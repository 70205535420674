import { Fragment, useState } from "react";
import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Placeholder from "../../components/images/placeholder.png";
import {
  AddToCartToast,
  priceFormatter,
  productPath,
} from "../../components/utils/Utils";
import { useDispatch } from "react-redux";
import { addToCart } from "../../slices/cartSlice";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProductQuickview(props) {
  const { open, onClose } = props;

  const product = props?.selectedProduct;

  const [selectedColor, setSelectedColor] = useState();

  const dispatch = useDispatch();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-5" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pt-14 pb-8 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                  <button
                    type="button"
                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                    onClick={onClose}
                  >
                    <span className="sr-only">Steng</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <div className="grid w-full grid-cols-1 items-start gap-y-8 gap-x-6 sm:grid-cols-12 lg:gap-x-8">
                    <div className="sm:col-span-4 lg:col-span-5">
                      <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-gray-100">
                        <img
                          src={
                            product?.main_picture?.file?.url
                              ? product?.main_picture?.file?.url
                              : Placeholder
                          }
                          alt={product?.main_picture?.alt}
                          className="object-cover object-center"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-8 lg:col-span-7">
                      <div className="border-b-2 border-blue-400 pb-2">
                        <h2 className="text-2xl font-bold text-gray-900 sm:pr-12">
                          {product?.title}
                        </h2>
                        <p>{product?.item_number}</p>
                      </div>

                      <section
                        aria-labelledby="information-heading"
                        className="mt-3"
                      >
                        <h3 id="information-heading" className="sr-only">
                          Produkt informasjon
                        </h3>

                        <div className="mt-6">
                          <h4 className="sr-only">Kort beskrivelse</h4>

                          <p className="text-sm text-gray-700">
                            {product?.short_description}
                          </p>
                        </div>

                        <div className="mt-10">
                          {product?.warehouse_status_type_id !== 4 &&
                            product?.sales_price > 0 && (
                              <p className="text-gray-900">
                                <span className="text-2xl ">
                                  {priceFormatter.format(
                                    product?.sales_price_incl_vat
                                  )}
                                </span>
                                <span className="italic text-gray-300 text-xs">
                                  {" "}
                                  Inkl. mva.
                                </span>
                              </p>
                            )}
                          {/* <p className="text-gray-300 font-sm">{priceFormatter.format(product?.sales_price)} Eks. mva.</p> */}
                        </div>
                      </section>

                      <section
                        aria-labelledby="options-heading"
                        className="mt-6"
                      >
                        <h3 id="options-heading" className="sr-only">
                          Produkt valg
                        </h3>

                        <form>
                          {/* Colors */}
                          {product?.colors && (
                            <div>
                              <h4 className="text-sm text-gray-600">Farge</h4>
                              <RadioGroup
                                value={selectedColor}
                                onChange={setSelectedColor}
                                className="mt-2"
                              >
                                <RadioGroup.Label className="sr-only">
                                  {" "}
                                  Velg en farge{" "}
                                </RadioGroup.Label>
                                <div className="flex items-center space-x-3">
                                  {product?.colors?.map((color) => (
                                    <RadioGroup.Option
                                      key={color.name}
                                      value={color}
                                      className={({ active, checked }) =>
                                        classNames(
                                          color.selectedColor,
                                          active && checked
                                            ? "ring ring-offset-1"
                                            : "",
                                          !active && checked ? "ring-2" : "",
                                          "-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none"
                                        )
                                      }
                                    >
                                      <RadioGroup.Label
                                        as="span"
                                        className="sr-only"
                                      >
                                        {" "}
                                        {color.name}{" "}
                                      </RadioGroup.Label>
                                      <span
                                        aria-hidden="true"
                                        className={classNames(
                                          color.bgColor,
                                          "h-8 w-8 border border-black border-opacity-10 rounded-full"
                                        )}
                                      />
                                    </RadioGroup.Option>
                                  ))}
                                </div>
                              </RadioGroup>
                            </div>
                          )}
                          <div className="mt-6">
                            {product?.warehouse_status_type_id !== 4 && (
                              <button
                                type="button"
                                onClick={() => {
                                  toast.custom((t) =>
                                    AddToCartToast({
                                      visible: t.visible,
                                      product: product,
                                    })
                                  );

                                  dispatch(addToCart(product));
                                  onClose();
                                }}
                                className="flex w-full items-center justify-center rounded-md border border-transparent bg-blue-400 py-3 px-8 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                              >
                                Legg til i Handlekurv
                              </button>
                            )}
                          </div>

                          <p className="absolute top-4 left-4 text-center sm:static sm:mt-6">
                            <Link
                              to={productPath(product)}
                              className="font-medium text-blue-400 hover:text-indigo-500"
                            >
                              Les mer om produktet
                            </Link>
                          </p>
                        </form>
                      </section>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
