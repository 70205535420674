import axios from "./axios";

const LOGIN_URL = "/oauth/token";
const SIGNUP_URL = "/users";
const UPDATE_PROFILE_URL = "/users";
const LOGOUT_URL = "/oauth/revoke";
const CURRENT_USER_URL = "/users/me";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

export async function getAccessToken() {
  const data = {
    grant_type: "client_credentials",
    scope: "public",
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  const response = await axios
    .post(LOGIN_URL, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });

  const r = await response;
  return r.access_token;
}

export async function getWebshopSettings() {
  return axios
    .get(`/company/settings/store`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
