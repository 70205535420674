import { Link } from "react-router-dom";
import Aggregat from "../../components/images/aggregat.jpg";
import { FadeIn, FadeInStagger } from "../utils/FadeIn";

const incentives = [
  {
    name: "Gratis frakt",
    description: "Om du handler for mer enn 3 000 kr",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-delivery-light.svg",
  },
  {
    name: "Kundeservice",
    description: "Vi hjelper deg å finne det riktige produktet for deg.",
    imageSrc: "https://tailwindui.com/img/ecommerce/icons/icon-chat-light.svg",
  },
  {
    name: "Rask handel",
    description: "Enkelt å finne produkter, enkelt å bestille",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-fast-checkout-light.svg",
  },
];

export default function WebshopIncentives() {
  return (
    <div className="bg-gray-50">
      <div className="mx-auto max-w-7xl py-24 sm:px-2 sm:py-32 lg:px-4">
        <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
          <div className="grid grid-cols-1 items-center gap-y-10 gap-x-16 lg:grid-cols-2">
            <div>
              <h2 className="text-4xl font-bold tracking-tight text-gray-900">
                Vi har en nettbutikk med alt du trenger til ditt inneklima
              </h2>
              <p className="mt-4 text-gray-500">
                Hos oss skal det være enkelt å finne produkter til ditt anlegg,
                enkelt å bestille og lett å få hjelp med å finne frem til riktig
                produkt.
              </p>
              <div className="mt-20">
                <Link
                  to="/produkter"
                  className="px-10 py-3 border-b-2 bg-blue-100 hover:border-blue-400 text-base font-medium text-blue-400 hover:text-blue-600 shadow-sm  sm:px-8"
                >
                  Se våre produkter
                </Link>
              </div>
            </div>

            <div className="mx-auto overflow-hidden rounded-lg bg-gray-100 w-[300px] shadow">
              <img
                src={Aggregat}
                alt=""
                width={300}
                height={300}
                className="mx-auto object-cover object-center"
              />
            </div>
          </div>
          <FadeInStagger className="mt-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
            {incentives.map((incentive) => (
              <FadeIn key={incentive.name} className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="h-16 w-16" src={incentive.imageSrc} alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    {incentive.name}
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    {incentive.description}
                  </p>
                </div>
              </FadeIn>
            ))}
          </FadeInStagger>
        </div>
      </div>
    </div>
  );
}
