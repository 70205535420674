export const articles = [
  {
    title: "Hvordan man bytter filter",
    content: "Informasjon kommer fortløpende.",
    dateAdded: "22-02-2023",
    dateUpdated: "22-02-2023",
    category: 1,
    author: {
      name: "Christoffer Skaar",
      href: "#",
      imageUrl: "",
    },
  },

  {
    title: "Det er viktig med service og vedlikehold",
    content: "Artikkel kommer snart",
    dateAdded: "22-02-2023",
    dateUpdated: "22-02-2023",
    category: 1,
    author: {
      name: "Christoffer Skaar",
      href: "#",
      imageUrl: "",
    },
  },

  {
    title: "Serviceavtale - trygt og enkelt",
    content: "Artikkel kommer snart",
    dateAdded: "22-02-2023",
    dateUpdated: "22-02-2023",
    category: 1,
    imageSrc: null,
    author: {
      name: "Christoffer Skaar",
      href: "#",
      imageUrl: "",
    },
  },
];

export const articleCategories = [
  {
    id: 1,
    title: "Balansert ventilasjon",
    description: "",
  },
];
