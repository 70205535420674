import axios from "./axios";

export async function fetchShippingTypes() {
  return axios({
    method: "get",
    url: "/shipping_types/",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}
