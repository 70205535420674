import { Fragment, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid";

export default function PMCombobox(props) {
  const [options, setOptions] = useState([{ id: 0, title: "Ikke valgt" }]);
  const [selected, setSelected] = useState(options[0]);
  const [query, setQuery] = useState("");

  const findIndexByValue = () => {
    // Find option by value, then return index
    const index = options.findIndex(
      (option) => option.value === props.values[props.name]
    );
    return index;
  };

  useEffect(() => {
    if (props.options) {
      // Add props.options to default options
      setOptions([options[0], ...props.options]);
      // After options is set, set current selected option
    }
  }, []);
  // add props.options to current options with default value

  useEffect(() => {
    setTimeout(() => {
      if (props.values[props.name]) {
        setSelected(options[findIndexByValue()]);
      }
    }, 50);
  }, [options]);

  function handleSelect(data) {
    setSelected(data);
    props.setFieldValue(props.name, data.value);
  }

  const { label, name, errors, touched, formik } = props;

  const filteredOptions =
    query === ""
      ? options
      : options?.filter((option) =>
          option?.title
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <div className="top-16 w-72 z-10 w-[100%]">
      <label htmlFor="name" className="block text-sm font-medium text-gray-700">
        <label> {label} </label>
      </label>
      <Combobox value={selected} onChange={handleSelect}>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
              displayValue={(option) => option?.title}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredOptions?.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredOptions?.map((option) => (
                  <Combobox.Option
                    key={option.id ? option.id : option?.title}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-teal-600 text-white" : "text-gray-900"
                      }`
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {option?.title}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-teal-600"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {formik && touched[name] && errors[name] && (
        <div className="flex mt-1 text-red-700 text-xs">
          <ExclamationCircleIcon className="w-4 text-xs" />
          <span className="ml-2">{errors[name]}</span>
        </div>
      )}
    </div>
  );
}
