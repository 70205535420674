import produce from "immer";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchProducts } from '../api/product'

const initialState = {
    isLoading: false,
    error: false,
    errors: null,
    data: [],
    product: {},
    count: 0,
    currentCount: 0,
    newestProductsCount: 0
};

export const initialController = {
    filters: [],
    page: 1,
    per_page: 25,
    query: '',
    sort: 'desc',
    sortBy: 'created_at',
    view: 'all'
}


export const getProductsAsync = createAsyncThunk(
    'products/getProductsAsync',
    async (controller = initialController, { rejectWithValue }) => {

        // if (! controller) return false;
        let response = await fetchProducts(controller);

        // If errors, reject and return response
        if (response.response?.status?.match(/404|500|401/)) return rejectWithValue(true);

        // If all good, return response.
        return response;
    })



const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getProductsAsync.pending, (state, action) => {
            state.isLoading = true;
        })
            .addCase(getProductsAsync.fulfilled, (state, action) => {
                state.isLoading = false;
                state.errros = [];
                state.error = false;
                state.data = action.payload.data;
                state.count = action.payload.count;
                state.currentCount = action.payload.currentCount;
                state.newestProductsCount = action.payload.newestProductsCount;

            })
            .addCase(getProductsAsync.rejected, (state, action) => {
                state.isLoading = false;
                state.error = true;
                state.errors = ['Something went wrong when trying to fetch products....?']
            })
    }
})


// export const {} = customerSlice.actions;

export const { reducer } = productSlice;

export default productSlice

export const selectProductById = (state, productId) => state.products.data.find(product => product.id == productId)
