import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ResourceEmpty } from "../../components/utils/ResourceEmpty";
import SearchQuery from "../../components/utils/SearchQuery";
import { getProductsAsync } from "../../slices/products";
import { Categories } from "./categories";
import { ProductsCategoriesList } from "./category-list";
import { ProductsList } from "./list";
import axios from "../../api/axios";
import { Link, useParams } from "react-router-dom";
import { ResourceLoading } from "../../components/utils/ResourceLoading";
import { Products } from "./products";
import { ArrowLeftIcon, ArrowRightCircleIcon } from "@heroicons/react/20/solid";
import { StoreNotActiveNotification } from "../../components/notifications";
import { useSettings } from "../../contexts/settingsContext";

export const CategoryPage = (props) => {
  const { id } = useParams();

  // mainCatgory (parent) - ID of category
  const { mainCategory } = props;

  const [state, setState] = useState({
    isLoading: false,
    error: false,
    errors: null,
  });
  const [categories, setCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);

  const getCategories = () => {
    setState({ loading: true });
    setCategoriesLoading(true);

    const request = {
      parents: true,
    };

    axios
      .get(`/products/categories/list?parents=true`)
      .then((response) => {
        setState({ loading: false, error: false });
        setCategories(response.data.data);
        setCategoriesLoading(false);
      })
      .catch((e) => {
        setState({ loading: false, error: true });
        setCategoriesLoading(false);
      });
  };

  function getCategory() {
    setState({ loading: true });
    setCategoriesLoading(true);
    axios
      .get(`/products/categories/${id}`)
      .then((response) => {
        setState({ loading: false, error: false });
        setCategories(response.data.children);
        setCategoriesLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setState({ loading: false, error: true });
        setCategoriesLoading(false);
      });
  }

  function getProducts() {
    setState({ loading: true });
    setProductsLoading(true);
    axios
      .get(`/products/categories/${id}/products`)
      .then((response) => {
        setState({ loading: false, error: false });
        setProducts(response.data.data);
        setProductsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setState({ loading: false, error: true });
      });
  }

  useEffect(() => {
    if (id) {
      getCategory();
      getProducts();
    } else {
      getCategories();
    }
  }, [id]);

  const { settings, setSettings } = useSettings();

  return (
    <div className="grid grid-cols-6 gap-5">
      <div className="col-span-2 sm:col-span-1 m-5 py-5 hidden sm:block">
        <div className="flex mb-5 mt-2 bg-blue-400 rounded-xl px-2 py-2 text-white">
          <ArrowLeftIcon className="mt-1 mr-2 h-5 w-5" />{" "}
          <Link to="/produkter">Alle produkter</Link>
        </div>
        <ProductsCategoriesList />
      </div>
      <div className="col-span-6 sm:col-span-4 ">
        {!settings?.checkout_active && <StoreNotActiveNotification />}

        <div className="py-5">
          {categories && categories.length > 0 && (
            <div className="mx-auto max-w-7xl pb-10">
              <div className="px-5 py-5 bg-blue-400 rounded-lg mb-2">
                <span className="relative text-white px-2 py-4 bold">
                  Kategorier
                </span>
              </div>
              <div className="rounded-lg py-5 px-5 bg-gray-50 shadow xl:flex xl:items-center">
                {categoriesLoading ? (
                  <ResourceLoading />
                ) : (
                  <Categories categories={categories} />
                )}
              </div>
            </div>
          )}

          {products && products.length > 0 && (
            <div className="mx-auto max-w-7xl pb-10">
              <div className="px-5 py-5 bg-blue-400 rounded-lg mb-2">
                <span className="relative text-white px-2 py-4 bold">
                  Produkter
                </span>
              </div>
              <div className="rounded-lg bg-gray-50 shadow xl:flex xl:items-center">
                {productsLoading ? (
                  <ResourceLoading />
                ) : (
                  <ProductsList products={products} />
                )}
              </div>
            </div>
          )}

          {!categoriesLoading &&
            !productsLoading &&
            !categories.length > 0 &&
            !products.length > 0 && (
              <div className="mx-auto max-w-7xl px-6 pb-10">
                <div className="rounded-lg bg-gray-50 shadow xl:flex xl:items-center">
                  <ResourceEmpty
                    title="Vi fant ingen kategorier eller produkter"
                    description="Prøv gjerne et annet søkeord eller ta kontakt med kundeservice"
                  />
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
