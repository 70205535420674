import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";
import { services } from "../../data/services";
import Lokkeberghagen from "../../components/images/projects/Lokkeberghagen.jpg";
import { TWclassNames } from "./Utils";
import { FadeIn, FadeInStagger } from "./FadeIn";

export default function ContentSection(props) {
  // Sticky = image is sticky to the right or left depending on imagePlacement prop.
  // imagePlacement = left or right. If left, image is on the left side of the text, and vice versa.
  // image should be a src to an image.
  const {
    image,
    sticky,
    imagePlacement,
    mainColorClass,
    subtitle,
    title,
    subheading,
    content,
  } = props;

  function imageColStart() {
    if (imagePlacement === "left") {
      return "lg:col-start-1";
    } else if (imagePlacement === "right") {
      return "lg:col-start-2";
    }
  }

  function contentColStart() {
    if (imagePlacement === "left") {
      return "lg:col-start-2";
    } else if (imagePlacement === "right") {
      return "lg:col-start-1";
    }
  }

  return (
    <FadeInStagger className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
          />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div
          className={TWclassNames(
            contentColStart(),
            "lg:col-span-2 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:gap-x-8 lg:px-8"
          )}
        >
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <FadeIn>
                <p className="text-base font-semibold leading-7 text-blue-600">
                  {subtitle}
                </p>
              </FadeIn>
              <FadeIn>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  {title}
                </h1>
              </FadeIn>

              <FadeIn>
                <p className="mt-6 text-xl leading-8 text-gray-700">
                  {subheading}
                </p>
              </FadeIn>
            </div>
          </div>
        </div>
        <FadeIn
          className={TWclassNames(
            imageColStart(),
            "-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:row-span-2 lg:row-start-1 lg:overflow-hidden"
          )}
        >
          <img
            className="object-contain max-w-none max-h-[600px] rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 "
            src={image}
            alt=""
          />
        </FadeIn>
        <div
          className={TWclassNames(
            contentColStart(),
            "lg:col-span-2 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:gap-x-8 lg:px-8"
          )}
        >
          <FadeIn className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              {content}
            </div>
          </FadeIn>
        </div>
      </div>
    </FadeInStagger>
  );
}
