import { Link } from "react-router-dom";
import { PageHeading } from "../../components/utils/pageHeading";
import bildeAggregat from "../../components/images/services/aggregat.webp";
import FeatureSection from "../../components/utils/featureSection";
import { WrenchIcon } from "@heroicons/react/20/solid";

const OrderServiceButton = () => {
  return (
    <Link
      to="/kontakt"
      className="bg-blue-400 hover:bg-blue-600 text-white px-4 py-4 rounded-xl mt-4 min-w-full"
    >
      Bestill reparasjon/ utbedring
    </Link>
  );
};

const features = [
  {
    title: "Aggregater",
    description:
      "Vi er eksperter på de fleste aggregatene på markedet og kan hjelpe deg med å bytte komponenter eller utføre større utbedringer.",
    icon: WrenchIcon,
  },
  {
    title: "Kanalsystem",
    description:
      "Kanalsystem er en viktig del av systemet. Vi kan hjelpe deg med å bytte ut deler av kanalsystemet eller utføre nødvendige utbedringer.",
    icon: WrenchIcon,
  },
];
// Set left/ right depending on what was before
function imagePlacement(index) {
  if (index % 2 === 0) {
    return "left";
  } else {
    return "right";
  }
}

export const Reparasjon = () => {
  return (
    <>
      <div>
        <PageHeading
          title={"Reparasjon & utbedring av ventilasjonsanlegg"}
          description={
            "Et ventilasjonsanlegg har mange komponenter og et kanalsystem som må fungere optimalt for å gi et godt inneklima. Derfor er det viktig at samtlige komponenter fungerer som det skal og at anlegget er i god stand."
          }
        />

        <FeatureSection
          subtitle="Reparasjon & utbedring"
          title="Vi gjør det som må til"
          subheading=" Vi hjelper deg med alt fra bytte av komponenter i ditt aggregat til å utføre større utbedringer på ditt ventilasjonsanlegg."
          features={features}
          image={bildeAggregat}
          content={<OrderServiceButton />}
        />
      </div>
    </>
  );
};
