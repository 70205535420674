import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ResourceEmpty } from "../../components/utils/ResourceEmpty";
import SearchQuery from "../../components/utils/SearchQuery";
import { getProductsAsync } from "../../slices/products";
import { ProductsCategoriesList } from "./category-list"
import { ProductsList } from "./list"


export const Parts = () => {
  const productState = useSelector((store) => {
    return store.products
  })

  const { isLoading, error, errors } = productState

  const products = productState.data
  const dispatch = useDispatch();
  const [controller, setController] = useState({
    filters: [
      { name: 'online_store', value: true },
      { name: 'status', value: 'active' },
      { name: 'hasSpareParts', value: true }
      // { name: 'categories', value: '44' }
    ],
    page: 1,
    per_page: 25,
    categories: '',
    query: '',
    sort: 'asc',
    sortBy: 'created_at',
    view: 'all',
  });


  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 1,
      query: newQuery
    });
  };

  // const handleCategorySelect = () => {
  //   setController({
  //     ...controller,
  //     filters: [
  //       ...controller.filters,
  //       { name: 'categories', value: selectedCategories }
  //     ]
  //   });
  // }


  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage
    });
  };


  useEffect(() => {
    const payload = {
      filters: controller.filters,
      page: controller.page,
      per_page: controller.per_page,
      query: controller.query,
      sort: controller.sort,
      sortBy: controller.sortBy,
      view: controller.view,
    }
    dispatch(getProductsAsync(payload));
  }, [dispatch, controller])





  return (
    <div>
      <div className="mx-auto max-w-7xl py-12 px-6 lg:py-16 lg:px-8">
        <div className="rounded-lg bg-indigo-700 px-6 py-6 md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
          <div className="xl:w-0 xl:flex-1">
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-3xl">Finn reservedeler og tilbehør</h2>
            <p className="mt-3 max-w-3xl text-lg leading-6 text-indigo-200">
              Søk etter modellnavn eller artikkelnummer
            </p>
          </div>
          <div className="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
            <SearchQuery
              query={controller.query}
              onChange={handleQueryChange}
              label="Søk etter produkt"
              placeholder="Artikkelnummer eller navn"
            />
          </div>


        </div>
      </div>

      <div className="mx-auto max-w-7xl px-6 pb-10 lg:px-8">
        <div className="rounded-lg bg-gray-50 shadow xl:flex xl:items-center">
          {products?.length > 0 ?
            <ProductsList products={products} isLoading={isLoading} error={error} errors={errors} />
            : <ResourceEmpty description="Vi fant dessverre ingen produkter med reservedeler. Prøv gjerne et annet søkeord eller ta kontakt med kundeservice" />
          }

        </div>
      </div>
    </div>
  )
}