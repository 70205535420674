import { Link, Outlet } from "react-router-dom"
import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  ArchiveBoxIcon as ArchiveBoxIconMini,
  ArrowUturnLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  EllipsisVerticalIcon,
  FolderArrowDownIcon,
  MagnifyingGlassIcon,
  PencilIcon,
  UserPlusIcon,
} from '@heroicons/react/20/solid'
import {
  ArchiveBoxIcon as ArchiveBoxIconOutline,
  Bars3Icon,
  BellIcon,
  FlagIcon,
  HomeIcon,
  InboxIcon,
  NewspaperIcon,
  NoSymbolIcon,
  PencilSquareIcon,
  ShoppingCartIcon,
  UserCircleIcon,
  WrenchScrewdriverIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

export const CustomerLayout = () => {

      const sidebarNavigation = [
        { name: 'Oversikt', href: '/min-side', icon: HomeIcon, current: true },
        { name: 'Ordrer', href: '/min-side/ordrer', icon: ShoppingCartIcon, current: false },
        { name: 'Rapporter', href: '/min-side/rapporter', icon: WrenchScrewdriverIcon, current: false },
        { name: 'Saker', href: '/min-side/saker', icon: InboxIcon, current: false },
      ]


  const [open, setOpen] = useState(false)


    return (
        <>
        <div className="flex mx-auto w-10/12 flex-col border-2 rounded-xl shadow p-2 bg-gray-200 mt-5">
          {/* Bottom section */}
          <div className="flex min-h-0 flex-1 overflow-hidden">
            {/* Narrow sidebar*/}
            <nav aria-label="Sidebar" className="hidden rounded-xl border-4 lg:block lg:flex-shrink-0 lg:overflow-y-auto lg:bg-gray-800">
              <div className="relative flex w-20 flex-col space-y-3 p-3">
                {sidebarNavigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.current ? 'bg-gray-900 text-white' : 'text-gray-400 hover:bg-gray-700',
                      'flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg'
                    )}
                  >
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-6 w-6" aria-hidden="true" />
                  </Link>
                ))}
              </div>
            </nav>
  
            {/* Main area */}
            <main className="min-w-0 flex-1 border-t border-gray-200 xl:flex">
                <Outlet/>
            </main>
          </div>
        </div>
      </>
    )
}