import { ContentServicemontor } from "./vacancies/servicemontor";
import { ContentServicetekniker } from "./vacancies/servicetekniker";

export const vacancies = [
  {
    id: "servicetekniker",
    title: "Ledig stilling som Servicetekniker",
    description: "Alt innen reparasjon og service på ventilasjonsanlegg",
    path: "/stillinger/servicetekniker",
    content: ContentServicetekniker,
  },
  {
    id: "servicemontor",
    title: "Ledig stilling som Servicemontør",
    description: "Alt innen reparasjon og service på ventilasjonsanlegg",
    path: "/stillinger/servicemontor",
    content: ContentServicemontor,
  },
];
