import { AtSymbolIcon } from "@heroicons/react/24/outline";
import { PhoneIcon } from "@heroicons/react/24/solid";
import placeholder from "../../components/images/placeholder.png";
import { employees } from "../../data/employees";
import Vacancies from "../../components/home/vacancies";
import { vacancies } from "../../data/vacancies";
import { ContentServicetekniker } from "../../data/vacancies/servicetekniker";
import { useParams } from "react-router-dom";

// Find the current page by looking inside vacancies array
// and return the content
const findPage = (id) => {
  const page = vacancies.find((item) => item.id === id);
  return page;
};

const path = window.location.pathname;

export const StillingerPage = () => {
  const { id } = useParams();

  const page = findPage(id);

  return <page.content />;
};
