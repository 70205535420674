import { CircleStackIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export const ResourceError = (props) => {
    const { errors, onRefresh } = props;
    return (
        <div className="text-center mx-auto py-16">
            <ExclamationTriangleIcon
                className="mx-auto h-12 w-12 text-gray-400"
            />

            <h3 className="mt-2 text-sm font-medium text-gray-900">Noe gikk galt</h3>
            <p className="mt-1 text-sm text-gray-500">Siden kunne ikke lastes inn</p>
            <p className="mt-10 text-xs text-gray-500">{errors}</p>

            <div className="mt-6">
                {onRefresh &&
                    <button
                        type="button"
                        className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        <CircleStackIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                        Last inn på nytt
                    </button>
                }
            </div>
        </div>
    )
}