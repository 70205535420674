import { Link } from "react-router-dom";
import ContentSection from "../../components/utils/contentSection";
import { PageHeading } from "../../components/utils/pageHeading";
import illustrasjon from "../../components/images/services/illustrasjon.jpg";
import bildeRegulering from "../../components/images/services/Regulering.jpg";
import bildeFilterservice from "../../components/images/services/Filterservice.jpg";
import bildeMontor from "../../components/images/services/montor.jpg";
import bildeAggregat from "../../components/images/services/aggregat.webp";
import bildeTegning from "../../components/images/services/tegning.png";

export const MonteringVentilasjon = () => {
  function supportContent() {
    return (
      <div className="flex flex-col">
        <p className="font-bold">
          Informasjon som kan være nyttig for deg, før du bestemmer deg
        </p>
        <Link
          to="/tjenester/full-service"
          className="bg-gray-600 hover:bg-gray-400 text-white px-4 py-2 rounded-xl mt-4"
        >
          Full service
        </Link>
        <Link
          to="/tjenester/filterservice"
          className="bg-gray-600 hover:bg-gray-400 text-white px-4 py-2 rounded-xl mt-4"
        >
          Filterservice
        </Link>
        <Link
          to="/tjenester/reparasjoner"
          className="bg-gray-600 hover:bg-gray-400 text-white px-4 py-2 rounded-xl mt-4"
        >
          Reparasjon
        </Link>

        <div className="mt-10 italic">
          Kontakt oss for mer informasjon om våre serviceavtaler.
        </div>

        <Link
          to="/kontakt"
          className="bg-blue-400 hover:bg-blue-600 text-white px-4 py-2 rounded-xl mt-4"
        >
          Kontakt oss
        </Link>
      </div>
    );
  }

  return (
    <>
      <div>
        <PageHeading
          title={"Montering av ventilasjon"}
          description={
            "Å sikre et friskt og energieffektivt inneklima starter med en godt prosjektert og korrekt installert ventilasjonsløsning. Vår tjeneste tilbyr komplett montering av balanserte ventilasjonsanlegg tilpasset din bolig eller bedrift. Med år av ekspertise i bransjen, sikrer vi at ditt nye system ikke bare møter, men overgår, de strenge kravene til energieffektivitet og inneklima."
          }
        />

        <ContentSection
          image={illustrasjon}
          imagePlacement="right"
          subtitle="Prosjektering"
          title="Grundig behovsanalyse"
          subheading="Vi starter med en full evaluering av dine spesifikke behov, inkludert romstørrelse, bygningens layout og eksisterende isolasjonsforhold."
          content=""
        />

        <ContentSection
          image={bildeTegning}
          imagePlacement="left"
          subtitle="Skreddersydd løsninger"
          title="Et unikt anlegg for ditt hjem"
          subheading="Våre løsninger er skreddersydd for å maksimere effektiviteten og integreres sømløst med bygningens estetikk og strukturelle begrensninger."
          content=""
        />

        <ContentSection
          image={bildeAggregat}
          imagePlacement="right"
          subtitle="Premium utstyr og materialer"
          title="Kun det beste er godt nok"
          subheading="Kvalitet er kjernen i vårt tilbud, og vi benytter oss av markedsledende produkter for å sikre varighet og pålitelighet."
          content=""
        />

        <ContentSection
          image={bildeMontor}
          imagePlacement="left"
          subtitle="Profesjonell montering"
          title="Eksperthåndverk i hver installasjon"
          subheading="Våre montører sørger for at ditt nye ventilasjonsanlegg blir installert med presisjon og respekt for ditt hjem."
          content=""
        />

        {/* <ContentSection
          imagePlacement="right"
          subtitle="Energibesparelser"
          title="Reduser kostnader, øk komforten"
          subheading="Gjennom avansert teknologi og smart design, finjusterer vi systemet for å redusere energiforbruk og øke inneklimakvaliteten."
          content=""
        /> */}

        <ContentSection
          image={bildeRegulering}
          imagePlacement="right"
          subtitle="Optimal Ytelse"
          title="Presisjonsbalansering for maksimal effektivitet"
          subheading="Vi balanserer tilluft og avtrekk for å sikre et balansert inneklima, dagens krav samtidig som vi sørger for et behagelig og energieffektivt inneklima hele året."
          content=""
        />

        <ContentSection
          image={bildeFilterservice}
          imagePlacement="left"
          subtitle="Service og oppfølging"
          title="Vi gir oss ikke her..."
          subheading="Etter installasjonen tilbyr vi full opplæring for at du skal kunne betjene og vedlikeholde systemet ditt."
          content={supportContent()}
        />

        <ContentSection
          imagePlacement="right"
          subtitle="Etter-Service Support"
          title="Din tilfredshet, vår prioritet"
          subheading="Vi står bak vårt arbeid med garantier som sikrer din tilfredshet og systemets kontinuerlige ytelse."
          content=""
        />
      </div>
    </>
  );
};
