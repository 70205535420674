import { Link, useParams } from "react-router-dom";
import { fetchFaqCateogry } from "../../../api/faq";
import { useEffect, useState } from "react";
import { PMNotice } from "../../../components/PMNotice";
import { ResourceLoading } from "../../../components/utils/ResourceLoading";
import { imgPlaceholder } from "../../../components/utils/Utils";

export const FaqCategoryPage = () => {
  // Get slug from params
  const { slug } = useParams();

  const [itemState, setItemState] = useState({
    isLoading: false,
    success: false,
    errors: null,
    faqCategory: {},
  });

  const { faqCategory } = itemState;

  const fetchFaqCategory = async () => {
    setItemState({ ...itemState, isLoading: true });

    const payload = {
      slug: slug,
    };

    const response = await fetchFaqCateogry(payload);
    if (response.status !== 200) {
      setItemState({ ...itemState, errors: response, isLoading: false });
    } else {
      setItemState({
        ...itemState,
        faqCategory: response?.data?.data,
      });
    }
  };

  useEffect(() => {
    fetchFaqCategory();
  }, [slug]);

  function childCategories() {
    return (
      // Child cateogires title
      <>
        <div className="mt-10 flex items-center justify-between">
          <h3 className="text-lg font-semibold text-gray-900">
            Underkategorier
          </h3>
        </div>

        <div className="grid grid-cols-3 gap-6 sm:grid-cols-5">
          {faqCategory?.children?.length > 0 ? (
            faqCategory?.children?.map((child, index) => (
              <div
                key={child?.id}
                className="relative flex flex-col gap-4 p-4 bg-white rounded-lg shadow-md"
              >
                <div>
                  <div className="flex items-center gap-x-4 text-xs">
                    <time
                      dateTime={child?.updated_at}
                      className="text-gray-500"
                    >
                      {child?.updated_at}
                    </time>
                  </div>
                  <div className="group relative max-w-xl">
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <Link to={"/hjelp/faq/kategori/" + child?.slug}>
                        <span className="absolute inset-0" />
                        {child?.title}
                      </Link>
                    </h3>
                    <p className="mt-5 text-sm leading-6 text-gray-600">
                      {child?.description}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <PMNotice
              title={"Her var det tomt"}
              description={
                "Innlegg i denne kategorien er under utvikling, vennligst prøv igjen senere"
              }
              severity={"info"}
            />
          )}
        </div>
      </>
    );
  }

  function categoryView() {
    return (
      <>
        <div className="mt-20 flex items-center justify-between">
          <h3 className="text-lg font-semibold text-gray-900">Innlegg</h3>
        </div>
        <div className="mt-2 space-y-20 lg:mt-4 lg:space-y-20">
          {faqCategory?.posts?.length > 0 ? (
            faqCategory?.posts?.map((post, index) => (
              <article
                key={post?.id}
                className="relative isolate flex flex-col gap-8 lg:flex-row"
              >
                <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
                  <img
                    src={imgPlaceholder}
                    alt=""
                    className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover"
                  />
                  <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <div>
                  <div className="flex items-center gap-x-4 text-xs">
                    <time dateTime={post?.updated_at} className="text-gray-500">
                      {post?.updated_at}
                    </time>
                    <span className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">
                      {faqCategory?.title}
                    </span>
                  </div>
                  <div className="group relative max-w-xl">
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <Link to={"/hjelp/faq/innlegg/" + post?.slug}>
                        <span className="absolute inset-0" />
                        {post?.title}
                      </Link>
                    </h3>
                    <p
                      className="mt-5 text-sm leading-6 text-gray-600"
                      dangerouslySetInnerHTML={{ __html: post?.content }}
                    ></p>
                  </div>
                  <div className="mt-6 flex border-t border-gray-900/5 pt-6">
                    <div className="relative flex items-center gap-x-4">
                      <img
                        src={imgPlaceholder}
                        alt=""
                        className="h-10 w-10 rounded-full bg-gray-50"
                      />
                      <div className="text-sm leading-6">
                        <p className="font-semibold text-gray-900">
                          <a href={post?.author?.href}>
                            <span className="absolute inset-0" />
                            av Airon AS
                          </a>
                        </p>
                        <p className="text-gray-600">Administrator</p>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            ))
          ) : (
            <PMNotice
              title={"Her var det tomt"}
              description={
                "Innlegg i denne kategorien er under utvikling, vennligst prøv igjen senere"
              }
              severity={"info"}
            />
          )}
        </div>
      </>
    );
  }

  if (itemState.isLoading) {
    <ResourceLoading />;
  }

  return (
    <div>
      <section>
        <div className="overflow-hidden bg-white">
          <div className="relative mx-auto max-w-7xl py-16 px-6 lg:px-8">
            <div className="absolute top-0 bottom-0 left-3/4 hidden w-screen lg:block" />
            <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
              <div>
                <h2 className="text-lg font-semibold text-blue-400">FAQ</h2>
                <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl border-b-2 pb-3 border-blue-400">
                  {faqCategory?.id
                    ? faqCategory?.title
                    : "Ingen kategori funnet"}
                </h3>
                <p className=" text-gray-400 mt-2">
                  {faqCategory?.id ? faqCategory?.description : ""}
                </p>
              </div>
            </div>

            <div className="mt-8 lg:mt-0">
              <div className="mx-auto text-base ">{childCategories()}</div>
            </div>
            <div className="mt-8 lg:mt-0">
              <div className="mx-auto text-base ">
                {faqCategory?.id ? (
                  categoryView()
                ) : (
                  <PMNotice
                    title={"Kunne ikke laste inn kategori"}
                    description={
                      "Vennligst prøv igjen senere eller prøv en annen kategori"
                    }
                    severity={"error"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="relative">
          <div className="mt-8 lg:mt-0">
            <div className="mx-auto min-w-full text-base lg:max-w-full">
              {/* {faq()} */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
