import { Helmet } from "react-helmet-async";
import logo from "../components/images/LOGO.png";
import { imgPlaceholder } from "../components/utils/Utils";
import { useParams } from "react-router-dom";

export const PageWrapper = (props) => {
  // logo with full url
  const { element, image, description, useIdAsTitle } = props;
  const url = "https://www.airon.no";
  const fullLogoUrl = url + logo;
  const fullImagePath = image ? url + image : url + imgPlaceholder;
  const { id } = useParams();

  const uppcaseFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const slugToTitle = (slug) => {
    const words = slug.split("-");
    const title = words.map((word) => uppcaseFirstLetter(word)).join(" ");
    return title;
  };

  const title =
    (useIdAsTitle ? slugToTitle(id) + " - " + props.title : props.title) +
    " - Airon AS";

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Airon AS" />
        <meta name="publisher" content="Airon AS" />
        <meta name="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta property="og:locale" content="nb_NO" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Airon AS" />
        <meta property="og:image" content={fullImagePath} />
        <meta property="og:image:alt" content="Airon AS" />
        <meta name="twitter:card" content={fullImagePath} />
        <meta name="twitter:site" content="@aironas" />
        <meta name="twitter:creator" content="@aironas" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <link rel="canonical" href="https://www.airon.no/" />
      </Helmet>

      {element}
    </>
  );
};
