import { CameraIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import Pellygata from "../../components/images/projects/pellygata.jpg";
export default function AboutUs() {
  const stats = [
    { label: "Stiftet", value: "2019" },
    { label: "Ansatte", value: "4" },
  ];

  return (
    <div className="overflow-hidden bg-white">
      <div className="relative mx-auto max-w-7xl py-16 px-6 lg:px-8">
        <div className="absolute top-0 bottom-0 left-3/4 hidden w-screen bg-gray-50 lg:block" />
        <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
          <div>
            <h2 className="text-lg font-semibold text-blue-400">Om Oss</h2>
            <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Airon AS
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:col-start-2 lg:row-start-1">
            <svg
              className="absolute top-0 right-0 -mt-20 -mr-20 hidden lg:block"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
              />
            </svg>
            <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                    className="rounded-lg object-cover object-center shadow-lg"
                    src={Pellygata}
                    alt="Prosjekt i Pellygata, Sarpsborg."
                    width={1184}
                    height={1376}
                  />
                </div>
                {/* <figcaption className="mt-3 flex text-sm text-gray-500">
                  <CameraIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                  <span className="ml-2">Photograph by Marcus O’Leary</span>
                </figcaption> */}
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="mx-auto max-w-prose text-base lg:max-w-none">
              <p className="text-lg text-gray-500">
                Airon AS ble stiftet i 2019 med mål om å levere
                ventilasjonstjenester og produkter til private, entreprenører og
                offentlig.
              </p>
            </div>
            <div className="prose prose-indigo mx-auto mt-5 text-gray-500 lg:col-start-1 lg:row-start-1 lg:max-w-none">
              <p>
                Med en god vekst siden start og solid kundebase fra tidligere,
                så har våre effektive, erfarene og dyktige medarbeidere bidratt
                til fullførelse og overlevering av flere større og mindre
                boligprosjekter samt flere hundre serviceoppdrag runt om i
                Østfold/ viken.
              </p>
              <p>
                Vi utfører alt som naturlig hører innenfor ventilasjonsbransjen.
                Vi spesialiserer oss også på spesielle tilpassninger og
                løsninger til alle typer bygg samt service, vedlikehold og drift
                av tekniske anlegg.
              </p>
              <p>
                Gode rutiner, effektivitet, dyktige medarbeidere og null feil
                tolleranse ved overlevering av våre prosjekt gjør oss til en
                partner du kan være trygg på.
              </p>
              <p>Vi har vårt kontor og lager på Grålum i Sarpsborg, Viken.</p>
            </div>

            {/* Stats section */}
            <div className="mt-10">
              <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                {stats.map((stat) => (
                  <div
                    key={stat.label}
                    className="border-t-2 border-gray-100 pt-6"
                  >
                    <dt className="text-base font-medium text-gray-500">
                      {stat.label}
                    </dt>
                    <dd className="text-3xl font-bold tracking-tight text-gray-900">
                      {stat.value}
                    </dd>
                  </div>
                ))}
              </dl>
              <div className="mt-10">
                <a
                  href="/om-oss/prosjekter"
                  className="text-base font-medium text-blue-400"
                >
                  Les mer om våre prosjekt
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </div>

              <div className="mt-10">
                <Link
                  to="/om-oss/ansatte"
                  className="text-base font-medium text-blue-400"
                >
                  Mer om våre ansatte
                  <span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
