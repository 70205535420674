export function totalInclVat(cart) {
  return cart.reduce((total, item) => {
    return total + item.sales_price_incl_vat * item.quantity;
  }, 0);
}


export function totalExVat(cart) {
  return cart.reduce((total, item) => {
    return total + item.sales_price * item.quantity;
  }, 0);
}
