import { Link } from "react-router-dom";
import ContentSection from "../../components/utils/contentSection";
import { PageHeading } from "../../components/utils/pageHeading";
import ServiceSection from "../../components/utils/serviceSection";
import illustrasjon from "../../components/images/services/illustrasjon.jpg";
import bildeRegulering from "../../components/images/services/Regulering.jpg";
import bildeFilterservice from "../../components/images/services/Filterservice.jpg";
import bildeMontor from "../../components/images/services/montor.jpg";
import bildeAggregat from "../../components/images/services/aggregat.webp";
import bildeTegning from "../../components/images/services/tegning.png";
import bildeAggregat2 from "../../components/images/aggregat.jpg";
import bildeRens1 from "../../components/images/services/rens1.jpg";
import bildeRens2 from "../../components/images/services/rens2.jpg";
import bildeRapport from "../../components/images/services/rapport.png";
import bildeKamera from "../../components/images/services/kamera.png";
import FeatureSection from "../../components/utils/featureSection";
import { WrenchIcon } from "@heroicons/react/20/solid";

const ContactButton = () => {
  return (
    <Link
      to="/kontakt"
      className="bg-blue-400 hover:bg-blue-600 text-white px-4 py-2 rounded-xl mt-4 w-full"
    >
      Kontakt oss
    </Link>
  );
};

const OrderServiceButton = () => {
  return (
    <Link
      to="/kontakt"
      className="bg-blue-400 hover:bg-blue-600 text-white px-4 py-2 rounded-xl mt-4 min-w-full"
    >
      Bestill service
    </Link>
  );
};

const articles = [
  // {
  //   "subtitle": "Grunnlaget for effektiv inneklimakontroll",
  //   "title":
  //     "Hva er balansert ventilasjon og hvorfor er det viktig for ditt hjem?",
  //   "subheading":
  //     "Balanserte ventilasjonsanlegg representerer det moderne svaret på behovet for energieffektiv og effektiv luftutveksling i boliger og leiligheter. Disse systemene gir en kontrollert måte å ventilere på ved å levere frisk luft og fjerne stagnerende luft samtidig. Med økende fokus på energieffektivitet, har disse systemene blitt avgjørende i å redusere energikostnader og forbedre total komfort og luftkvalitet i innemiljøer.",
  // },
  {
    "subtitle": "Vedlikehold av balansert ventilasjon",
    "title": "Viktigheten av regelmessig vedlikehold",
    "subheading":
      "Periodisk vedlikehold av ditt balanserte ventilasjonsanlegg sikrer at alle deler fungerer som de skal og forlenger systemets levetid. Det innebærer vanligvis en sjekkliste som omfatter rensing av ventilasjonskanaler, bytte av filtre, sjekk av komponenter og sørge for at systemet er fritt for blokkeringer. Våre serviceteknikere kan utføre disse oppgavene med presisjon, som bidrar til et bedre inneklima og reduserte energiutgifter.",
    "image": bildeFilterservice,
  },
  {
    "subtitle": "Profesjonelle tjenester for et sunnere hjem",
    "title": "Velg profesjonell service for ditt ventilasjonsanlegg",
    "subheading":
      "Profesjonell service for ditt balanserte ventilasjonsanlegg er mer enn bare en rutinemessig sjekk; det er en investering i din boligs langsiktige helse. En kvalifisert servicetekniker vil ikke bare utføre standard vedlikeholdsoppgaver, men også diagnostisere potensielle problemer før de blir større reparasjoner, og sikre at systemet ditt opererer med høyeste mulige effektivitet.",
    "image": bildeRegulering,
  },
  {
    "subtitle": "Feilsøking av ventilasjonsanlegg",
    "title": "Forebygging og reparasjon",
    "subheading":
      "Et balansert ventilasjonsanlegg kan oppleve en rekke vanlige problemer som blokkerte filtre, redusert luftstrøm eller uvanlige lyder som tyder på mekaniske eller elektriske problemer. Å kjenne til disse problemene og vite hvordan man kan feilsøke dem, kan spare deg for tid og penger. Regelmessig vedlikehold og tidlig deteksjon av feil er nøkkelen til å holde anlegget i god stand.",
    image: bildeKamera,
  },
  {
    "subtitle": "Hold ditt ventilasjonsanlegg i toppskikk",
    "title": "Slik planlegger du effektiv service for ditt anlegg",
    "subheading":
      "Regelmessig planlagt service for ditt balanserte ventilasjonsanlegg sikrer at systemet ditt alltid fungerer optimalt. Dette inkluderer å lage en vedlikeholdsplan som tar hensyn til de spesifikke behovene til din bolig og systemet, samt anbefalinger fra produsenten. Riktig timing for utskifting av deler og grundig rengjøring kan bidra til å opprettholde luftkvaliteten og energieffektiviteten.",
    "image": bildeRapport,
  },
  {
    "subtitle": "Hold deg oppdatert med de siste innen ventilasjonsteknologi",
    "title": "Nyheter og tips for vedlikehold av balansert ventilasjon",
    "subheading":
      "Den raske utviklingen innen ventilasjonsteknologi betyr at det stadig kommer nye forbedringer og oppdateringer som kan gjøre ditt hjem enda mer komfortabelt og energieffektivt. Å holde seg informert om de siste nyhetene, vedlikeholdstipsene og oppdateringene fra industrieksperter er essensielt for alle huseiere som ønsker å maksimere potensialet i sitt ventilasjonssystem.",
    "image": bildeAggregat2,
  },
  {
    "subtitle": "Din partner for et bedre inneklima",
    "title": "Få hjelp til vedlikehold av balansert ventilasjon",
    "subheading":
      "Ved å kontakte oss for vedlikehold av ditt balanserte ventilasjonsanlegg, sikrer du tilgang til dedikert og kvalifisert ekspertise. Vårt team av erfarne teknikere står klare til å tilby personlig service og skreddersy et vedlikeholdsprogram som passer dine behov og forbedrer ditt inneklima. Uansett om det er snakk om rutinemessig vedlikehold eller akutte reparasjonsbehov, er vi her for å hjelpe.",
    "content": <ContactButton />,
    "image": bildeRens1,
  },
];

const features = [
  {
    title: "Kontroll",
    description: "Samtlige komponenter i aggregat kontrolleres.",
    icon: WrenchIcon,
  },
  {
    title: "Inspeksjon",
    description:
      "Kanaler og ventiler inspiseres for skader og blokkeringer eller feil montasje.",
    icon: WrenchIcon,
  },
  {
    title: "Rens av ventilasjonskanaler",
    description: "Vi renser avtrekkskanaler, inntak og avkastkanaler.",
    icon: WrenchIcon,
  },
  {
    title: "Rens av utstyr",
    description: "Ventiler og komponenter i aggregat rengjøres.",
    icon: WrenchIcon,
  },
  {
    title: "Inn-regulering av anlegg",
    description:
      "Vi justerer inn anlegget slik at det fungerer optimalt, med hjelp av instrumenet.",
    icon: WrenchIcon,
  },
  {
    title: "Rapport",
    description:
      "Du får en tilstandsrapport med bilder, beskrivelse av utført arbeid, forslag til utbedringer ved behov og en inn-reguleringsprotokoll.",
    icon: WrenchIcon,
  },
];
// Set left/ right depending on what was before
function imagePlacement(index) {
  if (index % 2 === 0) {
    return "left";
  } else {
    return "right";
  }
}

export const FullService = () => {
  return (
    <>
      <div>
        <PageHeading
          title={"Full service"}
          description={
            "En full service for et balansert ventilasjonsanlegg dekker alle aspekter av systemets vedlikehold og funksjonalitet. Denne omfattende tjenesten sørger for at ditt ventilasjonssystem fungerer på toppnivå gjennom en detaljert inspeksjon, rengjøring, justering, og utskifting av komponenter etter behov. Enten det gjelder å forbedre luftkvaliteten, øke energieffektiviteten eller forlenge anleggets levetid, er full service et kritisk ledd i å vedlikeholde et sunt og behagelig inneklima."
          }
        />

        {articles.map((article, index) => (
          <ContentSection
            key={index}
            image={article.image}
            imagePlacement={imagePlacement(index)}
            subtitle={article.subtitle}
            title={article.title}
            subheading={article.subheading}
            content={article.content}
          />
        ))}

        <FeatureSection
          subtitle="Full service"
          title="Mer enn bare rens av kanaler"
          subheading="Alt du trenger for å holde ditt ventilasjonsanlegg i topp stand"
          features={features}
          image={bildeRens2}
          content={<OrderServiceButton />}
        />
      </div>
    </>
  );
};
