import axios from "./axios";

export async function fetchFaqCategories(params) {
  return axios({ method: "get", url: "/cdb/faq/categories", params: params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchFaqCateogry(payload, params = {}) {
  return axios({
    method: "get",
    url: `/cdb/faq/categories/${payload.slug}`,
    params: params,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchFaqPost(payload, params = {}) {
  return axios({
    method: "get",
    url: `/cdb/faq/posts/${payload.slug}`,
    params: params,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
}
