import { HomeIcon } from "@heroicons/react/20/solid";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <nav
      className="flex border-b border-t border-gray-200 bg-white"
      aria-label="Breadcrumb"
    >
      <ol
        role="list"
        className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8"
      >
        <li className="flex">
          <div className="flex items-center">
            <Link to="/" className="text-gray-400 hover:text-gray-500 mr-5">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
            <svg
              className="h-full w-6 flex-shrink-0 text-gray-200"
              viewBox="0 0 24 44"
              preserveAspectRatio="none"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
          </div>
        </li>
        {pathnames.length > 0 && (
          <>
            {pathnames.map((name, index) => {
              const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
              const isLast = index === pathnames.length - 1;
              const formattedName = name
                .replace(/-/g, " ")
                .replace(/\b\w/g, (c) => c.toUpperCase());
              return (
                <div className="flex items-center">
                  {index !== 0 && (
                    <svg
                      className="h-full w-6 flex-shrink-0 text-gray-200"
                      viewBox="0 0 24 44"
                      preserveAspectRatio="none"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                  )}
                  {isLast ? (
                    <li
                      key={name}
                      className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    >
                      <div className="flex items-center">{formattedName}</div>
                    </li>
                  ) : (
                    <Link
                      to={routeTo}
                      className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                      aria-current={isLast ? "page" : undefined}
                    >
                      {formattedName}
                    </Link>
                  )}
                </div>
              );
            })}
          </>
        )}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
