import { Link } from "react-router-dom";
import { PageHeading } from "../../components/utils/pageHeading";
import bildeAggregat from "../../components/images/services/aggregat.webp";
import bildeSmoke from "../../components/images/smoke.jpg";
import bildeSporfilm from "../../components/images/sporfilm.jpg";
import bildeInstrument from "../../components/images/instrument.jpg";
import FeatureSection from "../../components/utils/featureSection";
import { WrenchIcon } from "@heroicons/react/20/solid";
import ContentSection from "../../components/utils/contentSection";

const features = [
  {
    title: "Aggregater",
    description:
      "Vi er eksperter på de fleste aggregatene på markedet og kan hjelpe deg med å bytte komponenter eller utføre større utbedringer.",
    icon: WrenchIcon,
  },
  {
    title: "Kanalsystem",
    description:
      "Kanalsystem er en viktig del av systemet. Vi kan hjelpe deg med å bytte ut deler av kanalsystemet eller utføre nødvendige utbedringer.",
    icon: WrenchIcon,
  },
];
// Set left/ right depending on what was before
function imagePlacement(index) {
  if (index % 2 === 0) {
    return "left";
  } else {
    return "right";
  }
}

// La oss hjelpe deg med å skape et tryggere hjemmemiljø.

export const Radon = () => {
  return (
    <>
      <div>
        <PageHeading
          title={"Radon"}
          description={
            "Radon er en usynlig og luktfri gass som kan forårsake betydelig helsefare for deg og din familie. Som en naturlig forekommende gass som kommer fra uranforfall i jorden, kan den trenge inn i hjem via sprekker i fundamentet og bli fanget inne. I høye konsentrasjoner er radon den nest ledende årsaken til lungekreft i Norge. Vi er dedikert til å sikre at ditt innemiljø er sikkert og radonfritt. Med vår ekspertise, tilbyr vi effektive løsninger for radonmåling og tiltak for å redusere radonnivåene i ditt hjem. "
          }
        />

        <ContentSection
          image={bildeSmoke}
          imagePlacement="right"
          subtitle="Avdekke og redusere radon"
          title="La oss hjelpe deg med å skape et tryggere hjemmemiljø."
          subheading="Vi hjelper deg med å utføre måling i ditt hjem, og kan tilby effektive tiltak for å redusere radonnivåene."
          content={
            <Link
              to="/kontakt"
              className="bg-blue-400 hover:bg-blue-600 text-white px-4 py-4 rounded-xl mt-4 min-w-full"
            >
              Bestill konsultasjon
            </Link>
          }
        />

        <ContentSection
          image={bildeInstrument}
          imagePlacement="left"
          subtitle="Korttidsmåling"
          title="Et raskt og effektitvt resultat"
          subheading="Med hjelp av våre instrumenter kan vi utføre korttidsmåling i ditt hjem. Du får et raskt resultat, og vi raskt gi deg tilbud på effektive tiltak som er nødvendig for å sikkre ditt hjem fra radon."
          content={
            <Link
              to="/kontakt"
              className="bg-blue-400 hover:bg-blue-600 text-white px-4 py-4 rounded-xl mt-4 min-w-full"
            >
              Bestill korttidsmåling
            </Link>
          }
        />

        <ContentSection
          image={bildeSporfilm}
          imagePlacement="right"
          subtitle="Langtidsmåling"
          title="Et sikkert resultat med rapport fra laboratorium"
          subheading="Med våre radonsporfilmer kan vi utføre langtidsmåling i ditt hjem. Radonsporfilmene sendes til laboratorium for analyse, og du får en rapport med resultatene."
          content={
            <Link
              to="/kontakt"
              className="bg-blue-400 hover:bg-blue-600 text-white px-4 py-4 rounded-xl mt-4 min-w-full"
            >
              Bestill langtidsmåling
            </Link>
          }
        />
      </div>
    </>
  );
};
