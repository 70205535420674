import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";
import { services } from "../../data/services";
import Lokkeberghagen from "../../components/images/projects/Lokkeberghagen.jpg";
import { TWclassNames } from "./Utils";
import { FadeIn, FadeInStagger } from "./FadeIn";

export default function FeatureSection(props) {
  // Sticky = image is sticky to the right or left depending on imagePlacement prop.
  // imagePlacement = left or right. If left, image is on the left side of the text, and vice versa.
  // image should be a src to an image.
  const {
    image,
    imagePlacement,
    subtitle,
    title,
    subheading,
    features,
    content,
  } = props;

  function imageColStart() {
    if (imagePlacement === "left") {
      return "lg:col-start-1";
    } else if (imagePlacement === "right") {
      return "lg:col-start-2";
    }
  }

  function contentColStart() {
    if (imagePlacement === "left") {
      return "lg:col-start-2";
    } else if (imagePlacement === "right") {
      return "lg:col-start-1";
    }
  }

  return (
    <FadeInStagger className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:ml-auto lg:pl-4 lg:pt-4">
            <div className="lg:max-w-lg">
              <FadeIn>
                <h2 className="text-base font-semibold leading-7 text-blue-400">
                  {subtitle}
                </h2>
              </FadeIn>
              <FadeIn>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  {title}
                </p>
              </FadeIn>
              <FadeIn>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  {subheading}
                </p>
              </FadeIn>

              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features?.map((feature) => (
                  <FadeIn
                    key={feature.title}
                    className="flex flex-col relative pl-9"
                  >
                    <dt className="inline font-semibold text-gray-900">
                      {feature?.icon && (
                        <feature.icon
                          className="absolute left-1 top-1 h-5 w-5 text-blue-600"
                          aria-hidden="true"
                        />
                      )}
                      {feature.title}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </FadeIn>
                ))}
              </dl>
              <FadeIn className="mt-10 py-2 max-w-xl lg:max-w-none">
                {content}
              </FadeIn>
            </div>
          </div>
          <FadeIn className="flex items-start justify-end lg:order-first">
            <img
              src={image}
              alt="Product screenshot"
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
              width={2432}
              height={1442}
            />
          </FadeIn>
        </div>
      </div>
    </FadeInStagger>
  );
}
