import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { Link, useParams, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { Formik, Form } from "formik";
import axios from "../api/axios";
import { useEffect, useState } from "react";
import { ResourceLoading } from "../components/utils/ResourceLoading";
import { ResourceError } from "../components/utils/ResourceError";

export const ConfirmForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [token, setToken] = useState(searchParams.get("token"));

  const [itemState, setItemState] = useState({
    isLoading: false,
    error: false,
    errors: null,
    success: false,
    data: {},
  });

  const { data, isLoading, error, success } = itemState;

  const [hideConfirmation, setHideConfirmation] = useState(false);

  const fetchReport = async () => {
    try {
      // set state to isLoading
      setItemState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      const params = {
        token: token,
      };

      const response = await axios.get("/cdb/reports/reportAppointment", {
        params,
      });
      if (response?.data?.data) {
        // set state to not loading

        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          success: true,
          data: response?.data?.data,
        }));
        console.log(response);
      }
    } catch (err) {
      setItemState((prevState) => ({
        ...prevState,
        isLoading: false,
        success: false,
        errors: err?.response?.messages,
      }));

      console.log(err);
    } finally {
      setItemState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  function sendForm(values) {
    setItemState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const request = {
      ...values,
    };
    return axios.put(`/cdb/reports/confirmReportAgreement`, request);
  }

  useEffect(() => {
    // await 1 sec. before fetching the report so token can be set
    setTimeout(() => {
      if (token) {
        fetchReport();
      } else {
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          success: false,
        }));
      }
    }, 1000);
  }, [token]);

  if (isLoading) {
    return <ResourceLoading />;
  }

  if (error) {
    return <ResourceError errors="" />;
  }

  const initialValues = {
    token: token,
    submit: null,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, helpers) => {
        try {
          toast.promise(sendForm(values), {
            loading: "Sender...",
            success: (data) => {
              console.log(data);
              console.log(data.status);
              if (data.status === 203)
                throw new Error("Din avtale har dessverre utgått.");
              if (data.status !== 200) throw new Error("Noe gikk galt");
              setItemState((prevState) => ({
                ...prevState,
                isLoading: false,
              }));

              setHideConfirmation(true);

              return "Avtalen er bekreftet";
            },
            error: (error) => {
              console.log(error);

              setItemState((prevState) => ({
                ...prevState,
                isLoading: false,
              }));

              // setTimeout(() => {
              //   // Redirect the user to front-page
              //   window.location.href = "/";
              // }, 5000);

              return "Din avtale kunne ikke bekreftes";
            },
          });
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
        } catch (err) {
          console.log(err);
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
          }));

          setTimeout(() => {
            toast.dismiss();
            toast.error("Noe gikk galt...");
          }, 1000);
          console.error(err);
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }}
    >
      {(formik) =>
        isLoading ? (
          <ResourceLoading />
        ) : (
          <Form>
            <div className="mx-auto w-full max-w-7xl px-6 pb-16 sm:pb-24 lg:px-8">
              {!isLoading ? (
                <>
                  {success ? (
                    <div className="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
                      <p className="text-base font-semibold leading-8 text-blue-400">
                        Takk for din bestilling av oppdrag
                      </p>

                      <h1 className="mt-4 text-3xl font-bold tracking-tight text-green-500 sm:text-5xl">
                        Vi kommer til deg som avtalt
                      </h1>
                      <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
                        Informasjon om avtalen finner du her
                      </p>
                    </div>
                  ) : (
                    <div className="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
                      <p className="text-base font-semibold leading-8 text-blue-400">
                        Noe gikk galt.
                      </p>

                      <h1 className="mt-4 text-3xl font-bold tracking-tight text-green-500 sm:text-5xl">
                        Vi fant ikke din avtale.
                      </h1>
                      <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
                        Vennligst ta kontakt med oss{" "}
                        <Link className="text-blue-400" to="/kontakt">
                          her
                        </Link>
                      </p>
                      <p className="mt-4 text-xs italic leading-7 text-gray-600 sm:mt-6 sm:leading-8">
                        {/* Feilmelding: {message} */}
                      </p>
                    </div>
                  )}
                  <div className="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
                    {success && (
                      <>
                        <h2 className="mb-10">Avtalens informasjon</h2>

                        {/* List of order information start */}
                        <ul
                          role="list"
                          className="-my-5 divide-y divide-gray-200"
                        >
                          <li className="py-5">
                            <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                              <h3 className="text-sm font-semibold leading-5 text-gray-800">
                                Oppdragsnummer
                              </h3>
                              <p className="mt-1 text-sm text-gray-500">
                                {data?.report_number}
                              </p>
                            </div>
                          </li>

                          <li className="py-5">
                            <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                              <h3 className="text-sm font-semibold leading-5 text-gray-800">
                                Dato for oppmøte
                              </h3>
                              <p className="mt-1 text-sm text-gray-500">
                                {new Date(
                                  data?.appointment?.date
                                ).toLocaleDateString("no-NO")}
                              </p>
                            </div>
                          </li>

                          <li className="py-5">
                            <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                              <h3 className="text-sm font-semibold leading-5 text-gray-800">
                                {data?.appointment?.time
                                  ? "Tidspunkt"
                                  : "Tidsrom"}
                              </h3>
                              <p className="text-sm italic text-gray-400">
                                {data?.appointment?.time
                                  ? "Oppmøte skjer omtrent ved dette tidspunktet"
                                  : "Oppmøte skjer innenfor dette tidsrommet"}
                              </p>
                              <p className="mt-1 text-sm text-gray-500">
                                {data?.appointment?.time
                                  ? data?.appointment?.time
                                  : `${data?.appointment?.time_from} - ${data?.appointment?.time_too}`}
                              </p>
                            </div>
                          </li>

                          <li className="py-5">
                            <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                              <h3 className="text-sm font-semibold leading-5 text-gray-800">
                                Oppdragsadresse
                              </h3>
                              <p className="mt-1 text-sm text-gray-500">
                                {data?.delivery_address?.displayTitle}
                              </p>
                            </div>
                          </li>
                          <li className="py-5">
                            <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                              <h3 className="text-sm font-semibold leading-5 text-gray-800">
                                Oppdragsbeskrivelse
                              </h3>
                              <p
                                className="mt-1 text-sm text-gray-500"
                                dangerouslySetInnerHTML={{
                                  __html: data?.description,
                                }}
                              >
                                {/* Insert description (HTML allowed) safe   */}
                              </p>
                            </div>
                          </li>
                        </ul>

                        <div className="mt-10 py-4 border-t border-blue-400" />
                        {!hideConfirmation && !data?.appointment?.approved ? (
                          <div className="mt-10 flex flex-col justify-center">
                            <span className="italic">
                              Ved å bekrefte avtalen godtar du våre betingelser
                              for bruk av tjenester, salg og serviceavtaler
                            </span>
                            <button
                              type="button"
                              onClick={formik.submitForm}
                              className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-blue-400 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                            >
                              Bekreft avtale
                            </button>
                          </div>
                        ) : (
                          <div className="mt-10 flex flex-col justify-center">
                            <span className="italic text-center">
                              Avtalen er allerede bekreftet
                            </span>
                          </div>
                        )}
                      </>
                    )}

                    <div className="mt-10 flex justify-center">
                      <Link
                        to="/"
                        className="text-sm font-semibold leading-6 text-blue-400"
                      >
                        <span aria-hidden="true">&larr;</span>
                        Tilbake til forsiden
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                <ResourceLoading />
              )}
              <></>
            </div>
          </Form>
        )
      }
    </Formik>
  );
};

export const ConfirmAgreement = () => {
  return (
    <div className="bg-gray-100">
      <div className="mx-auto max-w-7xl py-16 px-6 sm:py-24 lg:px-8">
        <div className="relative bg-white shadow-xl">
          <h2 className="sr-only">Bekreft din avtale</h2>
          <div className="grid grid-cols-1 lg:grid-cols-3">
            {/* Contact information */}
            <div className="relative overflow-hidden bg-blue-400 py-10 px-6 sm:px-10 xl:p-12">
              <div
                className="pointer-events-none absolute inset-0 sm:hidden"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 h-full w-full"
                  width={343}
                  height={388}
                  viewBox="0 0 343 388"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                    fill="url(#linear1)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear1"
                      x1="254.553"
                      y1="107.554"
                      x2="961.66"
                      y2="814.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 sm:block lg:hidden"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 h-full w-full"
                  width={359}
                  height={339}
                  viewBox="0 0 359 339"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                    fill="url(#linear2)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear2"
                      x1="192.553"
                      y1="28.553"
                      x2="899.66"
                      y2="735.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 lg:block"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 h-full w-full"
                  width={160}
                  height={678}
                  viewBox="0 0 160 678"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                    fill="url(#linear3)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear3"
                      x1="192.553"
                      y1="325.553"
                      x2="899.66"
                      y2="1032.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <h3 className="text-lg font-medium text-white">Kontakt oss</h3>
              <p className="mt-6 max-w-3xl text-base text-indigo-50">
                Passer ikke avtalen for deg? Ta kontakt med oss.
              </p>
              <dl className="mt-8 space-y-6">
                <dt>
                  <span className="sr-only">Telefonnummer</span>
                </dt>
                <Link to="tel:+4769142600">
                  <dd className="flex text-base text-indigo-50  hover:text-indigo-600">
                    <PhoneIcon
                      className="h-6 w-6 flex-shrink-0 text-indigo-200"
                      aria-hidden="true"
                    />
                    <span className="ml-3">+47 691 42 600</span>
                  </dd>
                </Link>
                <dt>
                  <span className="sr-only">E-post</span>
                </dt>
                <Link to="mailto:post@airon.no">
                  <dd className="flex text-base text-indigo-50 hover:text-indigo-600">
                    <EnvelopeIcon
                      className="h-6 w-6 flex-shrink-0 text-indigo-200"
                      aria-hidden="true"
                    />
                    <span className="ml-3">post@airon.no</span>
                  </dd>
                </Link>
              </dl>
              <ul role="list" className="mt-8 flex space-x-12">
                <li>
                  <a className="text-indigo-200 hover:text-indigo-100" href="#">
                    <span className="sr-only">Facebook</span>
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      aria-hidden="true"
                    >
                      <path
                        d="M22.258 1H2.242C1.556 1 1 1.556 1 2.242v20.016c0 .686.556 1.242 1.242 1.242h10.776v-8.713h-2.932V11.39h2.932V8.887c0-2.906 1.775-4.489 4.367-4.489 1.242 0 2.31.093 2.62.134v3.037l-1.797.001c-1.41 0-1.683.67-1.683 1.653v2.168h3.362l-.438 3.396h-2.924V23.5h5.733c.686 0 1.242-.556 1.242-1.242V2.242C23.5 1.556 22.944 1 22.258 1"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a className="text-indigo-200 hover:text-indigo-100" href="#">
                    <span className="sr-only">Twitter</span>
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      aria-hidden="true"
                    >
                      <path
                        d="M7.548 22.501c9.056 0 14.01-7.503 14.01-14.01 0-.213 0-.425-.015-.636A10.02 10.02 0 0024 5.305a9.828 9.828 0 01-2.828.776 4.94 4.94 0 002.165-2.724 9.867 9.867 0 01-3.127 1.195 4.929 4.929 0 00-8.391 4.491A13.98 13.98 0 011.67 3.9a4.928 4.928 0 001.525 6.573A4.887 4.887 0 01.96 9.855v.063a4.926 4.926 0 003.95 4.827 4.917 4.917 0 01-2.223.084 4.93 4.93 0 004.6 3.42A9.88 9.88 0 010 20.289a13.941 13.941 0 007.548 2.209"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>

            {/* Contact form */}
            <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
              {/* <h3 className="text-lg font-medium text-gray-900 pb-2 border-b-2 border-blue-400">
                Bekreft avtale
              </h3> */}

              {ConfirmForm()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
