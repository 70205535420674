import { AtSymbolIcon, PhoneIcon } from "@heroicons/react/20/solid";
import { imgPlaceholder } from "../../components/utils/Utils";

export const ContentServicemontor = () => {
  return (
    <>
      <div className="bg-gray-100 min-h-screen flex items-center justify-center p-5">
        <div className="bg-white lg:flex max-w-4xl mx-auto rounded-lg shadow-2xl overflow-hidden">
          <div className="lg:w-3/5 p-8 md:p-12">
            <section>
              <h3 className="font-bold text-2xl">En variert stilling</h3>
              <p className="text-gray-600 pt-2">
                Bli med i vårt team som Servicetekniker.
              </p>
            </section>

            <section className="mt-10">
              <div className="text-gray-700">
                <h3 className="text-xl font-semibold mb-2">Om Stillingen</h3>
                <p>
                  Vi søker en dyktig servicemontør for å styrke vårt team. Du
                  vil jobbe med varierte oppgaver innen ventilasjon, inkludert
                  feilsøking, reparasjon, service, rens, justering/innregulering
                  og mye mer.
                </p>
              </div>

              <div className="mt-6">
                <h3 className="text-xl font-semibold mb-2">Kvalifikasjoner</h3>
                <ul className="list-disc list-inside text-gray-600">
                  <li>Erfaring med ventilasjonssystemer</li>
                  <li>Grunnleggende datakunnskaper</li>
                  <li>God teknisk forståelse</li>
                  <li>Evne til god kommunikasjon og samarbeid </li>
                  <li>Selvstendig, lojal og pliktoppfyllende</li>
                  <li>Utadvendt og serviceinnstilt</li>
                  <li>Norsk skriftlig og muntlig</li>
                  <li>Førerkort klasse B</li>
                </ul>
              </div>

              <div className="mt-6">
                <h3 className="text-xl font-semibold mb-2">Vi Tilbyr</h3>
                <ul className="list-disc list-inside text-gray-600">
                  <li>Konkurransedyktig lønn</li>
                  <li>Utviklingsmuligheter</li>
                  <li>God pensjonsordning</li>
                  <li>Dynamisk arbeidsmiljø</li>
                </ul>
              </div>
            </section>
          </div>

          <div className="lg:w-2/5 bg-blue-100 p-8">
            <div className=" mb-6">
              <p className="text-gray-600 pt-2">
                Ta kontakt med meg om du er intressert i stillingen.
              </p>
              <div className="w-32 h-32 mx-auto rounded-full overflow-hidden">
                <img
                  className="h-48 w-full object-cover transition delay-150 duration-300 ease-in-out"
                  src={imgPlaceholder}
                  alt="employee"
                />
              </div>
              <h3 className="text-xl font-semibold text-gray-700 mt-4">
                Christoffer Skaar
              </h3>
              <p className="text-gray-700">Daglig leder</p>

              <ul role="list" className="mt-6 flex gap-x-6">
                <li>
                  <a
                    href={"mailto:" + "cs@airon.no"}
                    className="text-gray-400 hover:text-gray-300"
                  >
                    <span className="sr-only">E-post</span>
                    <AtSymbolIcon className="h-5 w-5" />
                  </a>
                </li>
                <li>
                  <a
                    href={"tel:" + "+4790240502"}
                    className="text-gray-400 hover:text-gray-300"
                  >
                    <span className="sr-only">Mobil</span>
                    <PhoneIcon className="h-5 w-5" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
