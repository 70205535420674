import { ArrowPathRoundedSquareIcon, BuildingOfficeIcon, CalculatorIcon, ClipboardDocumentCheckIcon, ExclamationTriangleIcon, HashtagIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/outline'
import Filterservice from '../components/images/services/Filterservice.jpg'
import Regulering from '../components/images/services/Regulering.jpg'
import Rens from '../components/images/services/Rens.jpg'

export const services = [
  {
    title: 'Montering av balansert ventilasjonsanlegg',
    shortTitle: 'Montering',
    description: 'Vi utfører montasje av både store og små ventilasjonsanlegg.',
    icon: BuildingOfficeIcon,
    imageSrc: '',
    imageAlt: '',
  },
  {
    title: 'Inn-regulering av ventilasjonsanlegg',
    shortTitle: 'Inn-regulering',
    description: 'Innstilling av aggregat og ventiler med riktig utstyr er noe vi kan hjelpe deg med, slik at du får et optimalt inneklima.',
    icon: CalculatorIcon,
    imageSrc: Regulering,
    imageAlt: '',
  },
  {
    title: 'Full service',
    shortTitle: 'Full service',
    description: 'Vi utfører full service på ditt ventilasjonsanlegg, slik at du kan være trygg på at anlegget fungerer optimalt.',
    icon: ClipboardDocumentCheckIcon,
    imageSrc: '',
    imageAlt: '',
  },
  {
    title: 'Reparasjon',
    shortTitle: 'Reparasjon',
    description: 'Vi reparerer både små og store aggregat og vifter samt feilsøking og utbedring av feil på kanalnett.',
    icon: WrenchScrewdriverIcon,
    imageSrc: '',
    imageAlt: '',
  },
  {
    title: 'Filterservice',
    shortTitle: 'Filterservice',
    description: 'Vi utfører filterservice på alle typer ventilasjonsanlegg, og tar samtidig kontroll av komponenter og anlegg.',
    icon: HashtagIcon,
    imageSrc: Filterservice,
    imageAlt: '',
  },
  {
    title: 'Radonsanering & Måling',
    shortTitle: 'Radon',
    description: 'Vi utfører radonsanering og måling av radon i boliger og andre bygninger.',
    icon: ExclamationTriangleIcon,
    imageSrc: null,
    imageAlt: '',
  },



]