import Placeholder from "../../components/images/placeholder.png";
import { FadeIn, FadeInStagger } from "../../components/utils/FadeIn";
import { projects } from "../../data/projects";

export const ProjectsPage = () => {
  return (
    <div>
      <div className="overflow-hidden bg-white">
        <div className="relative mx-auto max-w-7xl py-16 px-6 lg:px-8">
          <div className="absolute top-0 bottom-0 left-3/4 hidden w-screen bg-gray-50 lg:block" />
          <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
            <div>
              <h2 className="text-lg font-semibold text-blue-400">Om Oss</h2>
              <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl border-b-2 pb-3 border-blue-400">
                Våre prosjekter
              </h3>
              <p className=" text-gray-400 mt-2">
                Her ser du noen av våre prosjekter som vi har utført eller er
                under utførelse
              </p>
            </div>
          </div>
          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:col-start-2 lg:row-start-1">
              <svg
                className="absolute top-0 right-0 -mt-20 -mr-20 hidden lg:block"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                />
              </svg>
              <div className="relative mx-auto max-w-prose text-base lg:max-w-none"></div>
            </div>
            <div className="mt-8 lg:mt-0">
              <FadeInStagger className="mx-auto max-w-prose text-base lg:max-w-none">
                <h3 className="sr-only">Projects</h3>
                {projects.map((project, index) => (
                  <FadeIn
                    key={index}
                    className="flex space-x-6 border-b border-gray-200 py-10"
                  >
                    <img
                      src={
                        project.projectImage
                          ? project.projectImage
                          : Placeholder
                      }
                      alt={project.title}
                      className="h-20 w-20 flex-none rounded-lg bg-gray-100 object-cover object-center sm:h-40 sm:w-40"
                    />
                    <div className="flex flex-auto flex-col">
                      <div>
                        <h4 className="font-medium text-gray-900">
                          <a href={project.href}>{project.title}</a>
                        </h4>
                        <p className="mt-2 text-sm text-gray-600">
                          {project.description}
                        </p>
                        <div className="mt-6 flex flex-1 items-end">
                          <dl className="flex space-x-4 divide-x divide-gray-200 text-sm sm:space-x-6">
                            {project?.projectCustomer && (
                              <div className="flex">
                                <dt className="font-medium text-gray-900">
                                  Kunde
                                </dt>
                                <dd className="ml-2 text-gray-700">
                                  {project.projectCustomer}
                                </dd>
                              </div>
                            )}
                          </dl>
                        </div>
                      </div>
                      <div className="mt-6 flex flex-1 items-end">
                        <dl className="flex space-x-4 divide-x divide-gray-200 text-sm sm:space-x-6">
                          <div className="flex">
                            <dt className="font-medium text-gray-900">Dato</dt>
                            <dd className="ml-2 text-gray-700">
                              {project.projectDate}
                            </dd>
                          </div>
                          {project?.projectSize && (
                            <div className="flex pl-4 sm:pl-6">
                              <dt className="font-medium text-gray-900">
                                Størelse
                              </dt>
                              <dd className="ml-2 text-gray-700">
                                {project.projectSize}
                              </dd>
                            </div>
                          )}
                        </dl>
                      </div>
                    </div>
                  </FadeIn>
                ))}
              </FadeInStagger>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
