import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";
import { services } from "../../data/services";
import Lokkeberghagen from "../../components/images/projects/Lokkeberghagen.jpg";
import { FadeIn, FadeInStagger } from "../utils/FadeIn";

export default function ServicesSection() {
  return (
    <div className="overflow-hidden bg-white py-14 sm:py-22">
      <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
          <div className="px-6 lg:px-0 lg:pt-4 lg:pr-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              <h2 className="text-lg font-semibold leading-8 tracking-tight text-blue-400">
                Våre tjenester
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Alt som tilhører et sunt inneklima
              </p>
              {/* <p className="mt-6 text-lg leading-8 text-gray-600">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque,
                iste dolor cupiditate blanditiis ratione.
              </p> */}
              <FadeInStagger className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {services.map((service) => (
                  <FadeIn key={service.title} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <service.icon
                        className="absolute top-1 left-1 h-5 w-5 text-indigo-600"
                        aria-hidden="true"
                      />
                      {service.title}
                    </dt>{" "}
                    <dd className="inline">{service.description}</dd>
                  </FadeIn>
                ))}
              </FadeInStagger>
            </div>
          </div>
          <div className="sm:px-6 lg:px-0">
            <div className="lg:mt-[100px] relative isolate overflow-hidden bg-blue-400 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pt-0 sm:pl-0 sm:pr-0 lg:mx-0 lg:max-w-none">
              <div
                className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white"
                aria-hidden="true"
              />
              <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                <img
                  src={Lokkeberghagen}
                  alt="Bilde av Løkkeberghagen, vårt prosjekt fra 2021"
                  width={2432}
                  height={1442}
                  className="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                />
              </div>
              <div
                className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
