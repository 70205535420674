import { Link } from "react-router-dom";
import { PageHeading } from "../../components/utils/pageHeading";
import bildeRapport from "../../components/images/services/rapport.png";
import FeatureSection from "../../components/utils/featureSection";
import { WrenchIcon } from "@heroicons/react/20/solid";

const OrderServiceButton = () => {
  return (
    <Link
      to="/kontakt"
      className="bg-blue-400 hover:bg-blue-600 text-white px-4 py-4 rounded-xl mt-4 min-w-full"
    >
      Bestill kontroll av ventilasjonsanlegg
    </Link>
  );
};

const features = [
  {
    title: "Aggregat",
    description: "Samtlige komponenter i aggregat kontrolleres.",
    icon: WrenchIcon,
  },
  {
    title: "Kanaler",
    description:
      "Kanaler og ventiler inspiseres for skader og blokkeringer eller feil montasje.",
    icon: WrenchIcon,
  },
  {
    title: "Luftmengder",
    description: "Luftmengder kontrolleres og sammenlignes mot dagens krav.",
    icon: WrenchIcon,
  },
  {
    title: "Rapport",
    description:
      "Du får en tilstandsrapport med bilder, beskrivelse av utført arbeid, forslag til utbedringer ved behov.",
    icon: WrenchIcon,
  },
];
// Set left/ right depending on what was before
function imagePlacement(index) {
  if (index % 2 === 0) {
    return "left";
  } else {
    return "right";
  }
}

export const KontrollVentilasjon = () => {
  return (
    <>
      <div>
        <PageHeading
          title={"Kontroll av ventilasjonsanlegg"}
          description={
            "Det er viktig at et ventilasjonsanlegg fungerer som det skal. Er du usikker på tilstanden på ditt ventilasjonsanlegg, så er vi her for å hjelpe deg."
          }
        />

        <FeatureSection
          subtitle="Full service"
          title="Ikke bare en liten sjekk"
          subheading="Trenger du en fullstendig tilstandsrapport og profesjonell hjelp til å få ditt ventilasjonsanlegg til å fungere optimalt? Da er du på riktig sted."
          features={features}
          image={bildeRapport}
          content={<OrderServiceButton />}
        />
      </div>
    </>
  );
};
