import React, { createContext, useContext, useState, useEffect } from "react";

// Create the context
export const SettingsContext = createContext();

// Create a provider component
export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({});

  useEffect(() => {
    // Fetch settings from localStorage when the component mounts
    const storedSettings = localStorage.getItem("settings");
    if (storedSettings) {
      try {
        const parsedSettings = JSON.parse(storedSettings);
        setSettings(parsedSettings);
      } catch (error) {
        console.error("Error parsing stored settings:", error);
        // Handle the error as you see fit, e.g., remove the invalid value
        localStorage.removeItem("settings");
      }
    }
  }, []);

  //   useEffect(() => {
  //     // Save settings to localStorage whenever they change
  //     localStorage.setItem('settings', JSON.stringify(settings));
  //   }, [settings]);

  return (
    <SettingsContext.Provider value={{ settings, setSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

// Custom hook to use the settings context
export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }
  return context;
};
