import Placeholder from "../../components/images/placeholder.png";
import { Disclosure } from "@headlessui/react";
import {
  FlagIcon,
  MinusSmallIcon,
  PlusSmallIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import HelpSection from "../../components/home/helpSection";
import { articleCategories, articles } from "../../data/articles";
import { useEffect, useState } from "react";
import { fetchFaqCategories } from "../../api/faq";
import { PMNotice } from "../../components/PMNotice";

export const HelpPage = () => {
  const [itemState, setItemState] = useState({
    isLoading: false,
    success: false,
    errors: null,
    faqCategories: [],
  });

  const { faqCategories } = itemState;

  const fetchParentCategories = async () => {
    setItemState({ ...itemState, isLoading: true });

    const params = {
      parent: true,
    };

    const response = await fetchFaqCategories(params);
    if (response.status !== 200) {
      setItemState({ ...itemState, errors: response, isLoading: false });
    } else {
      console.log(response.data.data);
      setItemState({
        ...itemState,
        faqCategories: response?.data?.data,
        isLoading: false,
      });
    }
  };

  useEffect(() => {
    fetchParentCategories();
  }, []);

  const faqs = [
    {
      question: "Mitt aggregat varsler filteralarm, hva gjør jeg?",
      answer:
        "Filter bør byttes eller så har du glemt å tilbakestille filterperiode.",
      links: [
        { title: "Bestill filter", href: "" },
        { title: "Hvordan man tilbakestiller filterperiode", href: "" },
      ],
    },
    // More questions...
  ];

  const posts = [
    {
      title: "Tilbakestille filterperiode",
      href: "#",
      category: { name: "Hjelp", href: "#" },
      description:
        "Vi forklarer hvordan du enkelt kan bestille filter og tilbakestille filterperiode på ditt aggregat",
      date: "Februar 12, 2023",
      datetime: "2023-02-12",
      imageUrl: "",
      author: {
        name: "Christoffer Skaar",
        href: "#",
        imageUrl: "",
      },
    },
  ];

  const faq = () => (
    <div className="bg-blue-500 w-full">
      <div className="mx-auto w-full px-6 py-24 sm:py-32 lg:py-40 lg:px-8">
        <div className="mx-auto max-w-prose divide-y divide-white/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-white">
            Vanlige spørsmål
          </h2>
          <dl className="mt-10 w-screen max-w-prose mx-auto space-y-6 divide-y divide-white/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                        <span className="text-base font-semibold leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-300">
                        {faq.answer}
                      </p>
                      {faq.links.map((link, index) => (
                        <div key={index} className="mt-10">
                          <Link
                            to="/om-oss/ansatte"
                            className="text-base font-medium text-white border-b-2 p-2 border-blue-200 hover:text-indigo-800 hover:border-indigo-800"
                          >
                            {link?.title}
                            <span aria-hidden="true"> &rarr;</span>
                          </Link>
                        </div>
                      ))}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );

  const articleView = () => (
    <div className="relative px-6 pt-16 pb-20 lg:px-8 lg:pt-24 lg:pb-28">
      <div className="absolute inset-0">
        <div className="h-1/3 bg-white sm:h-2/3" />
      </div>
      <div className="relative mx-auto">
        <div className="mx-auto mt-12 grid gap-5 lg:grid-cols-3">
          {articles.map((article) => (
            <div
              key={article.title}
              className="flex flex-col overflow-hidden rounded-lg shadow-lg"
            >
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src={article?.imageSrc ? article.imageSrc : Placeholder}
                  alt=""
                />
              </div>
              <div className="flex flex-1 flex-col justify-between bg-white p-6">
                <div className="flex-1">
                  <p className="text-sm font-medium text-indigo-600">
                    <a className="hover:underline">
                      {articleCategories[article.category - 1].title}
                    </a>
                  </p>
                  <a href={article.href} className="mt-2 block">
                    <p className="text-xl font-semibold text-gray-900">
                      {article.title}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      {article.description}
                    </p>
                  </a>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <a href={article.author.href}>
                      <span className="sr-only">{article.author.name}</span>
                      <img
                        className="h-10 w-10 rounded-full"
                        src={
                          article.author.imageUrl
                            ? article.author.imageUrl
                            : Placeholder
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      <a href={article.author.href} className="hover:underline">
                        {article.author.name}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const categoriesView = () => (
    <div className="relative pb-20 lg:pb-28">
      <div className="absolute inset-0">
        <div className="h-1/3 bg-white sm:h-2/3" />
      </div>
      <div className="relative mx-auto">
        <div className="mx-auto mt-4 grid gap-5 lg:grid-cols-3">
          {faqCategories?.map((category) => (
            <div
              key={category?.title}
              className="flex flex-col overflow-hidden rounded-lg shadow-lg"
            >
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src={category?.imageSrc ? category?.imageSrc : Placeholder}
                  alt=""
                />
              </div>
              <div className="flex flex-1 flex-col justify-between bg-white p-6">
                <div className="flex-1">
                  <p className="text-sm font-medium text-indigo-600"></p>
                  <Link
                    to={"/hjelp/faq/kategori/" + category?.slug}
                    className="mt-2 block"
                  >
                    <p className="text-xl font-semibold text-gray-900">
                      {category?.title}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      {category?.description}
                    </p>
                  </Link>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <span>
                      <span className="sr-only">Administrator</span>
                      <img
                        className="h-10 w-10 rounded-full"
                        src={Placeholder}
                        alt="Placeholder for AIRON AS"
                      />
                    </span>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      <span className="hover:underline">Administrator</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <section>
        <div className="overflow-hidden bg-white">
          <div className="relative mx-auto max-w-7xl py-16 px-6 lg:px-8">
            <div className="absolute top-0 bottom-0 left-3/4 hidden w-screen lg:block" />
            <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
              <div>
                <h2 className="text-lg font-semibold text-blue-400">Hjelp</h2>
                <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl border-b-2 pb-3 border-blue-400">
                  Oversikt
                </h3>
                <p className=" text-gray-400 mt-2">
                  Her finner du oversikt over vanlige spørsmål og artikler som
                  kan hjelpe deg å opprettholde ditt inneklima
                </p>
              </div>
            </div>
            <div className="mt-8 lg:mt-0">
              <div className="mx-auto text-base ">
                <div className="mt-10 flex items-center justify-between">
                  <h3 className="text-lg font-semibold text-gray-900">
                    FAQ Kateogrier
                  </h3>
                </div>
                {faqCategories.length > 0 ? (
                  categoriesView()
                ) : (
                  <PMNotice
                    title={"Ingen kategorier funnet"}
                    description={"Vennligst prøv igjen senere"}
                    severity={"error"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="relative">
          <div className="mt-8 lg:mt-0">
            <div className="mx-auto min-w-full text-base lg:max-w-full">
              {faq()}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
