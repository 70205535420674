import QueryString from "qs";
import axios from "./axios";

export async function fetchProduct(id) {
  return axios.get(`/products/` + id);
}

export async function fetchProducts(controller) {
  // let { filters, sort, sortBy, page, query, view, per_page } = controller;

  // const params = {
  //   query:    query,
  //   page:     page,
  //     per_page: per_page,
  //   sortBy:   sortBy,
  //   sort:     sort,
  //   filters:  filters,
  //   view:     view,
  // }

  return axios({
    method: "get",
    url: "/products/list",
    params: controller,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}
