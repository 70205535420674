import React, { useEffect } from 'react'
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { ResourceLoading } from '../utils/ResourceLoading';

function PrivateRoute({ children }) {
  // const loading = useSelector((state) => state.session.loading);
  const loading = false
  // const accessToken = useSelector((state) => state.session.accessToken);
  const accessToken = null
  // const currentUser = useSelector((state) => state.session.currentUser);
  const currentUser = null
  const location = useLocation();
  const fromLocation = (location.state)?.from;
  const previousLocation = fromLocation ? fromLocation : { pathname: '/bruker/login' };
  const dispatch = useDispatch();

  // Adds nessecary authorization headers to API requests.

  useEffect(() => {
    if (!accessToken) {
      toast.error('Du er ikke innlogget.')
    }
  }, []);

  if (accessToken && currentUser) {
    return children;
  } else if (loading) {
    return <ResourceLoading />
  } else if (!accessToken && !loading) {
    return <Navigate to={previousLocation} state={{ from: location }} replace />;
  } else {
    return <p>Something went wrong</p>;
  }
}

export default PrivateRoute

