export const CustomerOverview = () => {

    return ( 
        <div className="flex-1 p-5">
            <div className="min-w-0 flex-1">
    <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">Oversikt</h1>
</div>
<div className="flex-1">

<div className="mt-10 w-full grid grid-rows-6 grid-flow-col gap-4">
  <div className="row-span-6 border-2 rounded-xl shadow bg-white">
    <div className="p-2 items-center">
        1
    </div>
  </div>
  <div className="row-span-3 col-span-2 border-2 rounded-xl shadow bg-white">
    <div className="p-2 items-center">
        Ordrer
    </div>
  </div>
  <div className="row-span-3 col-span-2 border-2 rounded-xl shadow bg-white">
    <div className="p-2 items-center">
        Saker
    </div>
  </div>
</div>
</div>
        </div>
    )
}