import logo from "./logo.svg";
import "./App.css";
import { Home } from "./pages/home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeLayout from "./components/layouts/home";
import { Products } from "./pages/products/products";
import { Parts } from "./pages/products/parts";
import { PageNotFound } from "./pages/products/pageNotFound";
import { useEffect, useState } from "react";
import { AboutEmployees } from "./pages/about/employees";
import AboutUs from "./pages/about/about";
import Contact from "./pages/about/contact";
import { HelpPage } from "./pages/help/help";
import { ProjectsPage } from "./pages/about/projects";
import { PageUnderDevelopment } from "./pages/products/pageUnderDevelopment.js";
import { Toaster } from "react-hot-toast";
import PersistLogin from "./components/authentication/persistLogin";
import { ProductPage } from "./pages/products/productPage";
import { CustomerInfo } from "./pages/myPage/info";
import CustomerOrders from "./pages/myPage/orders";
import { CustomerReport } from "./pages/myPage/report";
import { CustomerOverview } from "./pages/myPage/overview";
import { CustomerCase } from "./pages/myPage/case";
import CustomerCases from "./pages/myPage/cases";
import { CustomerLayout } from "./components/layouts/customer";
import { CustomerOrder } from "./pages/myPage/order";
import CustomerReports from "./pages/myPage/reports";
import { CategoryPage } from "./pages/products/categoryPage";
import PrivateRoute from "./components/authentication/privateRoute";
import { ServiceBolig } from "./pages/services/serviceBolig";
import { ConfirmAgreement } from "./pages/confirmAgreement";
import OrderCheckout from "./pages/order/orderCheckout";
import { OrderSuccess } from "./pages/order/orderSuccess";
import { OrderCancel } from "./pages/order/orderCancel";
import { OrderSummary } from "./pages/order/orderSummary";
import { ResourceLoading } from "./components/utils/ResourceLoading";
import { SettingsProvider } from "./contexts/settingsContext";
import { MonteringVentilasjon } from "./pages/services/montering-ventilasjon";
import { FullService } from "./pages/services/full-service";
import { KontrollVentilasjon } from "./pages/services/kontroll";
import { Filterservice } from "./pages/services/filterservice";
import { Reparasjon } from "./pages/services/reparasjon";
import { Radon } from "./pages/services/radon";
import { AboutStillinger, Stillinger } from "./pages/about/stillinger";
import { StillingerPage } from "./pages/about/stillingerPage";
import { PageWrapper } from "./pages/pageWrapper";
import bildeFilterservice from "./components/images/services/Filterservice.jpg";
import bildeRens1 from "./components/images/services/rens1.jpg";
import bildeRapport from "./components/images/services/rapport.png";
import { FaqCategoryPage } from "./pages/help/faq.js/faqCategoryPage";
import { FaqPostPage } from "./pages/help/faq.js/faqPostPage";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center h-screen translate-x-0">
        <ResourceLoading />
      </div>
    );
  }

  const publicRoutes = [
    {
      title: "Alt som tilhører et sund inneklima",
      description:
        "Din leverandør av komplette ventilasjonsløsninger og profesjonell service.",
      path: "/",
      element: <Home />,
    },

    {
      title: "Handlekurv",
      description:
        "Din leverandør av komplette ventilasjonsløsninger og profesjonell service.",
      path: "/",
      element: <PageUnderDevelopment />,
    },

    {
      title: "Bekreft avtale",
      description: "Bekreft din avtale for ditt serviceoppdrag",
      path: "/bekreft-avtale",
      element: <ConfirmAgreement />,
    },

    {
      title: "Hjelp & FAQ",
      description: "",
      path: "/hjelp",
      element: <HelpPage />,
    },

    {
      title: "FAQ Kategori",
      description: "",
      path: "/hjelp/faq/kategori/:slug",
      element: <FaqCategoryPage />,
    },

    {
      title: "FAQ Innlegg",
      description: "",
      path: "/hjelp/faq/innlegg/:slug",
      element: <FaqPostPage />,
    },

    {
      title: "Kontakt oss",
      description: "",
      path: "/kontakt",
      element: <Contact />,
    },

    {
      title: "Om oss",
      description: "",
      path: "/om-oss",
      element: <AboutUs />,
    },

    {
      title: "Våre prosjekter",
      description: "",
      path: "/om-oss/prosjekter",
      element: <ProjectsPage />,
    },

    {
      title: "Våre ansatte",
      description: "",
      path: "/om-oss/ansatte",
      element: <AboutEmployees />,
    },

    {
      title: "Ledige stillinger",
      description: "",
      path: "/stillinger",
      element: <Stillinger />,
    },

    {
      useIdAsTitle: true,
      title: "Ledige stillinger",
      description: "",
      path: "/stillinger/:id",
      element: <StillingerPage />,
    },

    {
      useIdAsTitle: false,
      title: "Tjenester",
      description: "",
      path: "/tjenester",
      element: <PageUnderDevelopment />,
    },

    {
      useIdAsTitle: false,
      title: "Radon",
      description: "",
      path: "/tjenester/radon",
      element: <Radon />,
    },

    {
      useIdAsTitle: false,
      title: "Ventilasjon",
      description: "",
      path: "/tjenester/ventilasjon",
      element: <MonteringVentilasjon />,
    },

    {
      useIdAsTitle: false,
      title: "Filterservice",
      description: "",
      image: bildeFilterservice,
      path: "/tjenester/filterservice",
      element: <Filterservice />,
    },

    {
      useIdAsTitle: false,
      title: "Full service",
      description: "",
      image: bildeRens1,
      path: "/tjenester/full-service",
      element: <FullService />,
    },

    {
      useIdAsTitle: false,
      title: "Kontroll av ventilasjonsanlegg",
      description: "",
      image: bildeRapport,
      path: "/tjenester/kontroll",
      element: <KontrollVentilasjon />,
    },

    {
      useIdAsTitle: false,
      title: "Reparasjon",
      description: "",
      path: "/tjenester/reparasjon",
      element: <Reparasjon />,
    },

    {
      useIdAsTitle: false,
      title: "Produkt kategorier",
      description: "",
      path: "/kategorier",
      element: <CategoryPage />,
    },

    {
      useIdAsTitle: true,
      title: "Produkt kategorier",
      description: "",
      path: "/kategorier/:id",
      element: <CategoryPage />,
    },
    {
      useIdAsTitle: false,
      title: "Produkter",
      description: "",
      path: "/produkter",
      element: <Products />,
    },
    {
      useIdAsTitle: true,
      title: "Produkt",
      description: "",
      path: "/produkter/:id",
      element: <ProductPage />,
    },

    {
      useIdAsTitle: false,
      title: "Reservedeler",
      description: "",
      path: "/produkter/reservedeler",
      element: <Parts />,
    },

    {
      useIdAsTitle: true,
      title: "Produkt Kateogrier",
      description: "",
      path: "/produkter/kategorier/:id",
      element: <CategoryPage />,
    },

    {
      useIdAsTitle: false,
      title: "Betaling",
      description: "",
      path: "/ordre/betaling",
      element: <OrderCheckout />,
    },

    {
      useIdAsTitle: false,
      title: "Sammendrag",
      description: "",
      path: "/ordre/summary",
      element: <OrderSummary />,
    },

    {
      useIdAsTitle: false,
      title: "Ordre vellykket",
      description: "",
      path: "/ordre/success",
      element: <OrderSuccess />,
    },

    {
      useIdAsTitle: false,
      title: "Ordre kansellert",
      description: "",
      path: "/ordre/cancel",
      element: <OrderCancel />,
    },
  ];

  return (
    <SettingsProvider>
      <HomeLayout>
        <Toaster />

        <Routes>
          <Route element={<PersistLogin />}>
            <Route path="*" element={<PageNotFound />} />
            {/* Public Routes START */}
            {publicRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<PageWrapper {...route} />}
              />
            ))}
            {/* Public Routes END */}
          </Route>

          {/* Customer Page START */}
          <Route element={<CustomerLayout />}>
            <Route element={<PersistLogin />}>
              <Route element={<PrivateRoute />}>
                <Route path="/min-side" element={<CustomerOverview />} />
                <Route
                  path="/min-side/informasjon"
                  element={<CustomerInfo />}
                />
                <Route path="/min-side/saker" element={<CustomerCases />} />
                <Route path="/min-side/ordrer" element={<CustomerOrders />} />
                <Route path="/min-side/order/:id" element={<CustomerOrder />} />
                <Route
                  path="/min-side/rapporter"
                  element={<CustomerReports />}
                />
                <Route
                  path="/min-side/rapport/:id"
                  element={<CustomerReport />}
                />
              </Route>
            </Route>
          </Route>

          {/* Customer Page END */}
        </Routes>
      </HomeLayout>
    </SettingsProvider>
  );
}

export default App;
