import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Outlet } from "react-router-dom";
import { setConstantValue } from "typescript";
import { getAccessToken, getWebshopSettings } from "../../api/session";
import { ResourceError } from "../utils/ResourceError";
import { ResourceLoading } from "../utils/ResourceLoading";
import interceptor from "./interceptor";

export default function PersistLogin(props) {
  const [isLoading, setIsLoading] = useState(true);
  const currentUser = null;
  const error = null;

  const settings = localStorage.getItem("settings");

  async function setAccessToken() {
    const token = await getAccessToken();
    interceptor(token);
    setIsLoading(false);
  }

  async function getSettings() {
    const settings = await getWebshopSettings();
    if (settings) {
      localStorage.setItem("settings", JSON.stringify(settings.data));
      return settings.data;
    }
  }

  useEffect(() => {
    setAccessToken();
    setTimeout(() => {
      getSettings();
    }, 500);
  }, []);

  if (isLoading) return <ResourceLoading />;
  if (error) return <ResourceError />;

  return <Outlet />;
}
