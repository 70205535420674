import axios from "../../api/axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { ResourceLoading } from "../../components/utils/ResourceLoading";
import { priceFormatter } from "../../components/utils/Utils";

export const OrderSuccess = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  // Set order_id and session_id from searchParams
  const [orderToken, setOrderToken] = useState(searchParams.get("order_token"));
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState({});
  const [message, setMessage] = useState(null);

  // Fetch the order from API
  const fetchOrder = async () => {
    try {
      setIsLoading(true);
      const params = {
        order_token: orderToken,
      };

      const response = await axios.get("/checkout/success", {
        params,
      });
      if (response?.data?.order?.id) {
        setIsLoading(false);
        setSuccess(true);
        setData(response?.data);
        console.log(response);
      }
    } catch (err) {
      setMessage(err?.response?.data?.error);
      setIsLoading(false);
      setSuccess(false);
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (orderToken) {
      fetchOrder();
    } else {
      setIsLoading(false);
      setSuccess(false);
    }
  }, []);

  const { order } = data;

  return (
    <>
      <div className="mx-auto w-full max-w-7xl px-6 pb-16 sm:pb-24 lg:px-8">
        {!isLoading ? (
          <>
            {success ? (
              <div className="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
                <p className="text-base font-semibold leading-8 text-blue-400">
                  Takk for din bestilling.
                </p>

                <h1 className="mt-4 text-3xl font-bold tracking-tight text-green-500 sm:text-5xl">
                  Vi har mottatt din bestilling.
                </h1>
                <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
                  Din bestilling behandles fortløpende
                </p>
              </div>
            ) : (
              <div className="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
                <p className="text-base font-semibold leading-8 text-blue-400">
                  Noe gikk galt.
                </p>

                <h1 className="mt-4 text-3xl font-bold tracking-tight text-green-500 sm:text-5xl">
                  Vi fant ikke din ordre.
                </h1>
                <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
                  Vennligst ta kontakt med oss{" "}
                  <Link className="text-blue-400" to="/kontakt">
                    her
                  </Link>
                </p>
                <p className="mt-4 text-xs italic leading-7 text-gray-600 sm:mt-6 sm:leading-8">
                  Feilmelding: {message}
                </p>
              </div>
            )}
            <div className="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
              {success && (
                <>
                  <h2 className="mb-10">Ordreinformasjon</h2>

                  {/* List of order information start */}
                  <ul role="list" className="-my-5 divide-y divide-gray-200">
                    <li className="py-5">
                      <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                        <h3 className="text-sm font-semibold leading-5 text-gray-800">
                          Ordrenummer
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          {orderToken
                            ? order?.order_number
                            : "Ordrenummer ikke tilgjengelig"}
                        </p>
                      </div>
                    </li>
                    <li className="py-5">
                      <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                        <h3 className="text-sm font-semibold leading-5 text-gray-800">
                          Ordrestatus
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">Mottatt</p>
                      </div>
                    </li>
                    <li className="py-5">
                      <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                        <h3 className="text-sm font-semibold leading-5 text-gray-800">
                          Ordredato
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          {new Date(order?.order_date).toLocaleDateString(
                            "no-NO"
                          )}
                        </p>
                      </div>
                    </li>
                    <li className="py-5">
                      <div className="relative focus-within:ring-2 focus-within:ring-indigo-500">
                        <h3 className="text-sm font-semibold leading-5 text-gray-800">
                          Ordrebeløp
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          {priceFormatter.format(order?.total_amount_incl_vat)}
                          {" inkl. mva."}
                        </p>
                      </div>
                    </li>
                  </ul>
                </>
              )}

              <div className="mt-10 flex justify-center">
                <Link
                  to="/"
                  className="text-sm font-semibold leading-6 text-blue-400"
                >
                  <span aria-hidden="true">&larr;</span>
                  Tilbake til forsiden
                </Link>
              </div>
            </div>
          </>
        ) : (
          <ResourceLoading />
        )}
        <></>
      </div>
    </>
  );
};
