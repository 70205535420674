import { TWclassNames } from "../utils/Utils";

export function ProductWarehouseStatus({ product }) {
  const { warehouse_status_type_id } = product;

  const index = warehouse_status_type_id - 1;

  if ((!product && !warehouse_status_type_id) || warehouse_status_type_id === 0)
    return null;

  switch (warehouse_status_type_id) {
    case 1:
      return (
        <span
          className={TWclassNames(
            "bg-green-400 text-white",
            "inline-flex rounded-full px-4 py-1 text-xs font-semibold leading-5"
          )}
        >
          På lager
        </span>
      );
    case 2:
      return (
        <span
          className={TWclassNames(
            "bg-orange-200 text-black",
            "inline-flex rounded-full px-4 py-1 text-xs font-semibold leading-5"
          )}
        >
          {product?.stock ? product?.stock : 0} på lager
        </span>
      );
    case 3:
      return (
        <span
          className={TWclassNames(
            "bg-yellow-400 text-white",
            "inline-flex rounded-full px-4 py-1 text-xs font-semibold leading-5"
          )}
        >
          Bestillingsvare
        </span>
      );
    case 4:
      return (
        <span
          className={TWclassNames(
            "bg-red-400 text-white",
            "inline-flex rounded-full px-4 py-1 text-xs font-semibold leading-5"
          )}
        >
          Utgått
        </span>
      );
    default:
      return null;
  }
}
