import Placeholder from "../../components/images/placeholder.png";
import { FadeIn, FadeInStagger } from "../utils/FadeIn";
import { vacancies } from "../../data/vacancies";
import { Link } from "react-router-dom";

export default function Vacancies() {
  return (
    <div className="relative bg-gray-50 px-6 pt-16 pb-20 lg:px-8 lg:pt-24 lg:pb-28">
      <div className="absolute inset-0">
        <div className="h-1/3 bg-white sm:h-2/3" />
      </div>
      <div className="relative mx-auto max-w-7xl">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Ledige stillinger
          </h2>
          <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-500 sm:mt-4">
            Kan en av disse stillingene være noe for deg?
          </p>
        </div>
        <FadeInStagger className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
          {vacancies.map((item) => (
            <FadeIn
              key={item.title}
              className="flex flex-col overflow-hidden rounded-lg shadow-lg hover:scale-125"
            >
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover transition delay-150 duration-300 ease-in-out"
                  src={item?.imageSrc ? item.imageSrc : Placeholder}
                  alt=""
                />
              </div>
              <div className="flex flex-1 flex-col justify-between bg-white p-6">
                <div className="flex-1">
                  <p className="text-sm font-medium text-indigo-600">
                    <a className="hover:underline">{item?.category}</a>
                  </p>
                  <Link to={item.path} className="mt-2 block">
                    <p className="text-xl font-semibold text-gray-900">
                      {item.title}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      {item.description}
                    </p>
                  </Link>
                </div>
                <div className="mt-6 flex items-center"></div>
              </div>
            </FadeIn>
          ))}
        </FadeInStagger>
      </div>
    </div>
  );
}
