import { Link } from "react-router-dom";
import { FadeIn } from "./FadeIn";

export const PMCard = ({ title, description, imageUrl, path, buttonLabel }) => {
  return (
    <FadeIn className="col-span-1 h-full flex flex-col">
      <div className="relative shadow-xl sm:overflow-hidden sm:rounded-2xl flex-grow">
        <div className="absolute inset-0">
          <img className="h-full w-full object-cover" src={imageUrl} alt="" />
          <div className="absolute inset-0 bg-gradient-to-r from-blue-800 to-blue-400 mix-blend-multiply" />
        </div>
        <div className="relative flex sm:flex-row flex-col py-5 px-6 sm:py-24 lg:py-10 lg:px-8 justify-between">
          <div>
            <h1 className="text-sm font-bold tracking-tight sm:text-xl lg:text-2xl">
              <span className="block text-white">{title}</span>
              {/* <span className="block text-indigo-200">customer support</span> */}
            </h1>
            <p className="max-w-lg text-md text-indigo-200 sm:max-w-xl text-pretty break-normal">
              {description}
            </p>
          </div>
          <div className="mt-10 sm:mt-5">
            <Link
              to={path}
              className="flex items-center justify-center rounded-md border border-transparent bg-white px-3 py-3 text-base font-medium text-blue-700 shadow-sm hover:bg-indigo-50 sm:px-8 min-w-[200px]"
            >
              {buttonLabel}
              <span aria-hidden="true"> &rarr;</span>
            </Link>
          </div>
        </div>
      </div>
    </FadeIn>
  );
};
