import axios from "../../api/axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { categoryPath } from "../../components/utils/Utils";
import { FadeIn, FadeInStagger } from "../../components/utils/FadeIn";

export const ProductsCategoriesList = () => {
  const subCategories = [
    { name: "Totes", href: "#" },
    { name: "Backpacks", href: "#" },
    { name: "Travel Bags", href: "#" },
    { name: "Hip Bags", href: "#" },
    { name: "Laptop Sleeves", href: "#" },
  ];

  const [state, setState] = useState({
    isLoading: false,
    error: false,
    errors: null,
  });
  const [categories, setCategories] = useState([]);

  const getCategories = () => {
    setState({ loading: true });

    const request = {
      parents: true,
    };

    axios
      .get(`/products/categories/list?parents=true`)
      .then((response) => {
        setState({ loading: false, error: false });
        setCategories(response.data.data);
      })
      .catch((e) => {
        setState({ loading: false, error: true });
      });
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="w-full">
      <h3 className="sr-only">Kategorier</h3>
      <ul
        role="list"
        className="space-y-4 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900"
      >
        {categories.map((category) => (
          <li key={category.id} className="cursor-pointer hover:text-blue-400">
            <Link to={categoryPath(category)}>{category.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
