import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import SearchQuery from "../../components/utils/SearchQuery";
import { updateSearchParams } from "../../components/utils/Utils";
import { getProductsAsync } from "../../slices/products";
import { ProductsCategoriesList } from "./category-list";
import { ProductsList } from "./list";
import { useSettings } from "../../contexts/settingsContext";
import { StoreNotActiveNotification } from "../../components/notifications";
import PMSwitch from "../../components/utils/PMSwitch";
export const Products = (props) => {
  const dispatch = useDispatch();

  const productState = useSelector((store) => {
    return store.products;
  });

  const { isLoading, error, errors } = productState;

  const products = productState.data;
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState();
  const [controller, setController] = useState({
    filters: [
      { name: "warehouse_status_type", value: JSON.stringify([1, 2, 3]) },
    ],
    page: searchParams.get("page") ? searchParams.get("page") : 1,
    per_page: 25,
    categories: "",
    query: searchParams.get("query") ? searchParams.get("query") : "",
    sort: searchParams.get("sort") ? searchParams.get("sort") : "asc",
    sortBy: "created_at",
    view: "all",
    show_inactive: false,
  });

  const updateSearchParams = (controller) => {
    setSearchParams(controller);
  };

  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 1,
      query: newQuery,
    });
  };

  const handleCategorySelect = () => {
    setController({
      ...controller,
      filters: [
        ...controller.filters,
        { name: "categories", value: selectedCategories },
      ],
    });
  };

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleExpiredChange = (boolean) => {
    console.log(boolean);
    // remove filters with name warehouse_status_type from filters
    const filteredFilters = controller.filters.filter(
      (filter) => filter.name !== "warehouse_status_type"
    );

    const newFilters = [
      ...filteredFilters,
      {
        name: "warehouse_status_type",
        value: JSON.stringify(boolean ? [1, 2, 3, 4] : [1, 2, 3]),
      },
    ];

    setController({
      ...controller,
      filters: newFilters,
    });
  };

  const warehouseStatusTypeSelected = (id) => {
    const expiredFilter = controller.filters.find(
      (filter) => filter.name === "warehouse_status_type"
    );

    // Check if the 4 is included inside value and []
    if (expiredFilter) {
      const value = JSON.parse(expiredFilter.value);
      if (value.includes(id)) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    updateSearchParams(controller);
  }, [controller]);

  useEffect(() => {
    dispatch(getProductsAsync(controller));
  }, [dispatch, controller]);

  const { settings, setSettings } = useSettings();

  return (
    <div>
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-2 sm:col-span-1 m-5 px-10 py-5 hidden sm:block">
          <ProductsCategoriesList />
        </div>
        <div className="col-span-6 sm:col-span-4 ">
          {!settings?.checkout_active && <StoreNotActiveNotification />}

          <div className="m-5">
            <SearchQuery
              query={controller.query}
              onChange={handleQueryChange}
            />
          </div>
          <div className="flex m-5">
            <div>
              <PMSwitch
                label="Vis utgåtte produkter"
                checked={warehouseStatusTypeSelected(4)}
                onChange={(e) => {
                  handleExpiredChange(e.target.checked);
                }}
              />
            </div>

            {/* <div className="px-4">
              <PMSwitch
                label="Vis kun lagervarer"
                checked={warehouseStatusTypeSelected(1)}
                onChange={(e) => {
                  handleExpiredChange(e.target.checked);
                }}
              />
            </div> */}
          </div>
          <ProductsList
            products={products}
            isLoading={isLoading}
            error={error}
            errors={errors}
          />
        </div>
      </div>
    </div>
  );
};
