import Pellygata from '../components/images/projects/pellygata.jpg'


export const projects = [
  {
    title: 'Verksbyen',
    description: 'Levering og montering av komplett balansert ventilasjonsanlegg for 24 mindre leiligheter',
    projectCustomer: 'Arca Nova Entreprenør AS',
    projectDate: '2020',
    projectSize: '',
    projectType: 'Balansert ventilasjon',
    projectImage: '',
  },
  {
    title: 'Nygårdsåsen i Askim - Trinn 1',
    description: 'Levering og montering av komplett balansert ventilasjonsanlegg for 22 leiligheter m/ fellesarealer',
    projectCustomer: 'Øst AS',
    projectDate: '2020',
    projectSize: '',
    projectType: 'Balansert ventilasjon',
    projectImage: '',
  },
  {
    title: 'Tindlund Boliger, Trinn 1',
    description: 'Levering og montering av komplett balansert ventilasjonsanlegg for 3 leiligheter',
    projectCustomer: 'PEAB AS',
    projectDate: '2020',
    projectSize: '',
    projectType: 'Balansert ventilasjon',
    projectImage: '',
  },
  {
    title: 'Capjon Park',
    description: 'Levering og montering av komplett balansert ventilasjonsanlegg for 5 blokker med leiligheter',
    projectCustomer: 'Arca Nova Entreprenør AS',
    projectDate: '2020-2021',
    projectSize: '',
    projectType: 'Balansert ventilasjon',
    projectImage: '',
  },
  {
    title: 'Pellygata 25',
    description: 'Levering og montering av komplett balansert ventilasjonsanlegg for 24 mindre leiligheter',
    projectCustomer: 'Hersleth Entreprenør AS',
    projectDate: '2020',
    projectSize: '3 780 m3/h',
    projectType: 'Balansert ventilasjon',
    projectImage: Pellygata,
  },
  {
    title: 'Brannvifte i Pellygata 25',
    description: 'Levering og montering av komplett brannvifte for trappesjakt',
    projectCustomer: 'Hersleth Entreprenør AS',
    projectDate: '2020',
    projectSize: '19 199 m3/h',
    projectType: 'Brann-ventilasjon',
    projectImage: '',
  },
  {
    title: 'Strandbuen 24',
    description: 'Levering og montering av komplett balansert ventilasjonsanlegg for bolig med hybel',
    projectCustomer: 'Privat',
    projectDate: '2021',
    projectSize: '',
    projectType: 'Balansert ventilasjon',
    projectImage: '',
  },
  {
    title: 'Smedens Hage',
    description: 'Levering og montering av komplett balansert ventilasjonsanlegg for 30 leiligheter med garasjeanlegg',
    projectCustomer: 'Hersleth Entreprenør AS',
    projectDate: '2020',
    projectSize: '',
    projectType: 'Balansert ventilasjon',
    projectImage: '',
  },
  {
    title: 'Løkkeberghagen',
    description: 'Levering og montering av komplett balansert ventilasjonsanlegg for 30 leiligheter',
    projectCustomer: 'Arca Nova Entreprenør AS',
    projectDate: '2021',
    projectSize: '',
    projectType: 'Balansert ventilasjon',
    projectImage: '',
  },
  {
    title: 'NAV Moss, Prinsens gt. 5',
    description: 'Montering av balansert ventilasjonsanlegg, aggregater og utstyr',
    projectCustomer: 'GK Norge AS',
    projectDate: '2021-2022',
    projectSize: '40 000 m3/h',
    projectType: 'Balansert ventilasjon',
    projectImage: '',
  },
  {
    title: 'Østheimveien 17',
    description: 'Levering og montering av komplett balansert ventilasjonsanlegg for omsorgsboliger. 6 anlegg + anlegg for ventilering av fellesareal',
    projectCustomer: 'Arca Nova Entreprenør AS',
    projectDate: '2022',
    projectSize: 'ca. 2 648 m3/h',
    projectType: 'Balansert ventilasjon',
    projectImage: '',
  },

]