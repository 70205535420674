import { Link } from "react-router-dom";
import { PageHeading } from "../../components/utils/pageHeading";
import bildeFilterservice from "../../components/images/services/Filterservice.jpg";
import FeatureSection from "../../components/utils/featureSection";
import { WrenchIcon } from "@heroicons/react/20/solid";

const OrderServiceButton = () => {
  return (
    <Link
      to="/kontakt"
      className="bg-blue-400 hover:bg-blue-600 text-white px-4 py-4 rounded-xl mt-4 min-w-full"
    >
      Bestill filterservice eller serviceavtale
    </Link>
  );
};

const features = [
  {
    title: "Kontroll av komponenter",
    description: "Samtlige komponenter i aggregat kontrolleres og testes.",
    icon: WrenchIcon,
  },
  {
    title: "Innstillinger",
    description:
      "Funksjoner og Innstillinger kontrolleres og justeres ved behov.",
    icon: WrenchIcon,
  },
  {
    title: "Rengjøring",
    description: "Støv og smuss fjernes fra aggregat.",
    icon: WrenchIcon,
  },
  {
    title: "Nytt filter",
    description: "Så bytter vi selvfølgelig filter.",
    icon: WrenchIcon,
  },
  {
    title: "Rapport",
    description:
      "Du får en tilstandsrapport med bilder, beskrivelse av utført arbeid, forslag til utbedringer ved behov.",
    icon: WrenchIcon,
  },
];
// Set left/ right depending on what was before
function imagePlacement(index) {
  if (index % 2 === 0) {
    return "left";
  } else {
    return "right";
  }
}

export const Filterservice = () => {
  return (
    <>
      <div>
        <PageHeading
          title={"Filterservice"}
          description={
            "Jevnlig filterservice er viktig for å opprettholde et godt inneklima. Vi utfører filterservice på alle typer ventilasjonsanlegg."
          }
        />

        <FeatureSection
          subtitle="Filterservice"
          title="Ikke kun bytte av filter"
          subheading="Bruker du oss til å utføre filterservice på ditt ventilasjonsanlegg, så kan du være trygg på at anlegget ditt blir tatt godt vare på."
          features={features}
          image={bildeFilterservice}
          content={<OrderServiceButton />}
        />
      </div>
    </>
  );
};
