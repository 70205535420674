import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { PMField } from "../../components/utils/PMField";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { Formik, Form } from "formik";
import PMCombobox from "../../components/utils/PMCombobox";
import axios from "../../api/axios";
import { fetchCaseTypes } from "../../api/case";
import { useEffect, useState } from "react";
import { ResourceLoading } from "../../components/utils/ResourceLoading";
import { ResourceError } from "../../components/utils/ResourceError";
import { CalendarDaysIcon, CheckIcon } from "@heroicons/react/20/solid";

export function ContactForm() {
  const [itemState, setItemState] = useState({
    isLoading: false,
    error: false,
    errors: null,
    caseTypes: [],
  });

  const { caseTypes, isLoading, error } = itemState;

  // Get case types
  useEffect(() => {
    fetchAndMapTypeOptions();
  }, []);

  async function fetchAndMapTypeOptions() {
    setItemState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    // Fetch Case types from API?
    return fetchCaseTypes()
      .then((res) => {
        if (res.data) {
          var array = [];
          if (!res.data) return array;
          res.data.map((row, i) => {
            const rowParams = {
              value: row.id,
              title: row.title,
              order: i,
            };
            array.push(rowParams);
          });
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            caseTypes: array,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        // if (err?.data?.errors) {
        //   errors = mapErrors(err.data.errors);
        // } else {
        //   errors = ["Something went wrong"];
        // }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          error: true,
          errors: errors,
        }));
      });

    // Map into an array with ID and title?

    // Return the array
  }

  function sendForm(values) {
    setItemState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const request = {
      contactForm: {
        ...values,
      },
    };

    return axios.put(`/cases/contactForm`, request);
    // const res = axios.put(`/cases/contactForm`, request);

    // toast.promise(res, {
    //   loading: "Lagrer....",
    //   success: (data) => {
    //     if (data.status === 500) throw new Error("Server error");
    //     return "Data was saved to " + module;
    //   },
    //   error: "Something went wrong...could not save!",
    // });
  }

  const [isSent, setIsSent] = useState(false);
  const [caseState, setCaseState] = useState({});

  const initialValues = {
    id: null,
    name: null,
    mobile: null,
    email: null,
    address: null,
    postal: null,
    place: null,
    model_name: null,
    serial_number: null,
    description: null,
    case_type_id: 0,
    submit: null,
  };

  if (isLoading) {
    return <ResourceLoading />;
  }

  if (error) {
    return <ResourceError errors="" />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        name: Yup.string().required("Du må fylle i ditt navn"),
        mobile: Yup.string().required("Du må fylle i ditt mobilnummer"),
        email: Yup.string()
          .email("Din e-post ser ikke helt riktig ut...")
          .required("Du må fylle i din e-post"),
        address: Yup.string().required("Du må fylle i din adresse"),
        postal: Yup.string().required("Du må fylle i postnummer"),
        place: Yup.string().required("Du må fylle i sted"),
        // type: Yup.string().required("Du må velge type"),
        description: Yup.string().max(500),
      })}
      onSubmit={(values, helpers) => {
        try {
          toast.promise(sendForm(values), {
            loading: "Sender...",
            success: (data) => {
              console.log(data);
              if (data.status !== 200) throw new Error("Noe gikk galt");
              helpers.resetForm(initialValues);

              setItemState((prevState) => ({
                ...prevState,
                isLoading: false,
              }));

              setIsSent(true);

              setCaseState(data.data.data);

              console.log(caseState);

              return "Henvendelse ble sendt";
            },
            error: (error) => {
              return "Noe gikk feil...";
            },
          });
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
        } catch (err) {
          setTimeout(() => {
            toast.dismiss();
            toast.error("Noe gikk feil...");
          }, 1000);
          console.error(err);
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }}
    >
      {(formik) =>
        isLoading ? (
          <ResourceLoading />
        ) : caseState.case_number ? (
          <IsSentPlaceholder case_number={caseState.case_number} />
        ) : (
          <Form>
            <h3 className="text-lg font-medium text-gray-900 pb-2 border-b-2 border-blue-400">
              Opprett en henvendelse
            </h3>
            <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
              <div className="sm:col-span-2">
                <h3 className="text-md font-medium text-gray-900 mt-10">
                  Din kontaktinformasjon
                </h3>
              </div>

              <div className="col-span-2 sm:col-span-1">
                <PMField name="name" type="text" label="Navn" {...formik} />
              </div>

              <div className="col-span-2 sm:col-span-1">
                <PMField name="mobile" type="text" label="Mobil" {...formik} />
              </div>

              <div className="col-span-2 sm:col-span-1">
                <PMField name="email" type="text" label="E-post" {...formik} />
              </div>

              <div className="sm:col-span-2">
                <h3 className="text-md font-medium text-gray-900 mt-10">
                  Hvor holder du til?
                </h3>
              </div>

              <div className="col-span-2 sm:col-span-2">
                <PMField
                  name="address"
                  type="text"
                  label="Adresse"
                  {...formik}
                />
              </div>

              <div className="col-span-2 sm:col-span-2">
                <PMField
                  name="address2"
                  type="text"
                  label="Adresse / Leilighetsnummer"
                  {...formik}
                />
              </div>

              <div className="col-span-2 sm:col-span-1">
                <PMField
                  name="postal"
                  type="text"
                  label="Postnummer"
                  {...formik}
                />
              </div>

              <div className="col-span-2 sm:col-span-1">
                <PMField name="place" type="text" label="Sted" {...formik} />
              </div>

              <div className="sm:col-span-2">
                <h3 className="text-md font-medium text-gray-900 mt-5">
                  Informasjon om ditt anlegg
                </h3>
              </div>

              <div className="col-span-2 sm:col-span-1">
                <PMField
                  name="model_name"
                  type="text"
                  label="Modellnavn"
                  {...formik}
                />
              </div>

              <div className="col-span-2 sm:col-span-1">
                <PMField
                  name="serial_number"
                  type="text"
                  label="Serienummer"
                  {...formik}
                />
              </div>

              <div className="sm:col-span-2">
                <h3 className="text-md font-medium text-gray-900 mt-5">
                  Hva gjelder din henvendelse?
                </h3>
              </div>

              <div className="col-span-2 sm:col-span-2">
                <PMCombobox
                  name="case_type_id"
                  label="Henvendelse type"
                  options={caseTypes}
                  {...formik}
                />
              </div>

              <div className="sm:col-span-2">
                <PMField
                  name="description"
                  type="text"
                  multiline
                  label="Beskrivelse"
                  {...formik}
                />
              </div>

              <div className="col-span-2 sm:col-span-2 sm:flex sm:justify-end">
                <button
                  type="button"
                  onClick={formik.submitForm}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-400 text-base font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Send inn
                </button>
              </div>
            </div>
          </Form>
        )
      }
    </Formik>
  );
}

function IsSentPlaceholder({ case_number }) {
  return (
    <>
      <div className="justify-center">
        <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:p-6">
          <div>
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
              <CheckIcon
                className="h-6 w-6 text-green-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <div
                as="h3"
                className="text-base font-semibold leading-6 text-gray-900"
              >
                Din henvendelse er registrert
              </div>
              <div className="mt-2">
                <p className="text-md text-gray-500">SAK-{case_number}</p>
              </div>
              <div className="mt-2">
                <p className="mt-2 text-sm text-gray-500 italic">
                  Du hører fra oss så snart vi har behandlet din henvendelse.
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-5">
            <Link
              to="/"
              className="inline-flex justify-center rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Tilbake til startsiden
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default function Contact() {
  return (
    <div className="bg-gray-100">
      <div className="mx-auto max-w-7xl py-16 px-6 sm:py-24 lg:px-8">
        <div className="relative bg-white shadow-xl rounded-xl">
          <h2 className="sr-only">Kontakt Oss</h2>
          <div className="grid grid-cols-1 lg:grid-cols-3">
            {/* Contact information */}
            <div className="relative overflow-hidden bg-blue-400 py-10 px-6 sm:px-10 xl:p-12 rounded-xl">
              <div
                className="pointer-events-none absolute inset-0 sm:hidden"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 h-full w-full"
                  width={343}
                  height={388}
                  viewBox="0 0 343 388"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                    fill="url(#linear1)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear1"
                      x1="254.553"
                      y1="107.554"
                      x2="961.66"
                      y2="814.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 sm:block lg:hidden"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 h-full w-full"
                  width={359}
                  height={339}
                  viewBox="0 0 359 339"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                    fill="url(#linear2)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear2"
                      x1="192.553"
                      y1="28.553"
                      x2="899.66"
                      y2="735.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 lg:block"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 h-full w-full"
                  width={160}
                  height={678}
                  viewBox="0 0 160 678"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                    fill="url(#linear3)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear3"
                      x1="192.553"
                      y1="325.553"
                      x2="899.66"
                      y2="1032.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <h3 className="text-lg font-medium text-white">Kontakt oss</h3>
              <p className="mt-6 max-w-3xl text-base text-indigo-50">
                Ønsker du hjelp med reparasjon, service, andre henvendelser
                eller support? Fyll ut skjemaet for raskest mulig hjelp.
                <br />
                Men du kan også ringe ogg eller sende e-post.
              </p>
              <dl className="mt-8 space-y-6">
                <dt>
                  <span className="sr-only">Telefonnummer</span>
                </dt>
                <Link to="tel:+4769142600">
                  <dd className="flex text-base text-indigo-50  hover:text-indigo-600">
                    <PhoneIcon
                      className="h-6 w-6 flex-shrink-0 text-indigo-200"
                      aria-hidden="true"
                    />
                    <span className="ml-3">+47 691 42 600</span>
                  </dd>
                </Link>
                <dt>
                  <span className="sr-only">E-post</span>
                </dt>
                <Link to="mailto:post@airon.no">
                  <dd className="flex text-base text-indigo-50 hover:text-indigo-600">
                    <EnvelopeIcon
                      className="h-6 w-6 flex-shrink-0 text-indigo-200"
                      aria-hidden="true"
                    />
                    <span className="ml-3">post@airon.no</span>
                  </dd>
                </Link>
              </dl>

              {/* Åpningstider */}
              <h3 className="text-lg font-medium text-white mt-10">
                Åpningstider
              </h3>
              <p className="mt-6 max-w-3xl text-base text-indigo-50">
                Vi har vår support og kontor åpent alle dager i uken bortsett
                fra Lørdag, Søndag og røde dager.
              </p>
              <p className="mt-6 max-w-3xl text-base text-red-400 italic">
                I uke 29 og 30 har holder vi kontoret stengt. Vi er tilgjengelig
                på telefon og e-post.
              </p>
              <dl className="mt-8 space-y-6">
                <dt>
                  <span className="sr-only">Mandag-Torsdag</span>
                </dt>
                <dd className="flex text-base text-indigo-50  hover:text-indigo-600">
                  <CalendarDaysIcon
                    className="h-6 w-6 flex-shrink-0 text-indigo-200"
                    aria-hidden="true"
                  />
                  <div className="flex flex-col">
                    <span clasSName="ml-3">Mandag-Torsdag</span>
                    <span className="ml-3">08:00 - 15:00 </span>
                  </div>
                </dd>
                <dt>
                  <span className="sr-only">Fredag</span>
                </dt>
                <dd className="flex text-base text-indigo-50 hover:text-indigo-600">
                  <CalendarDaysIcon
                    className="h-6 w-6 flex-shrink-0 text-indigo-200"
                    aria-hidden="true"
                  />
                  <div className="flex flex-col">
                    <span className="ml-3">Fredag</span>
                    <span className="ml-3">08:00 - 13:00</span>
                  </div>
                </dd>
              </dl>

              <ul role="list" className="mt-8 flex space-x-12">
                <li>
                  <Link
                    className="flex text-white "
                    target="_blank"
                    to="https://www.facebook.com/aironventilasjonas/"
                  >
                    <span className="sr-only">Facebook</span>
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      aria-hidden="true"
                    >
                      <path
                        d="M22.258 1H2.242C1.556 1 1 1.556 1 2.242v20.016c0 .686.556 1.242 1.242 1.242h10.776v-8.713h-2.932V11.39h2.932V8.887c0-2.906 1.775-4.489 4.367-4.489 1.242 0 2.31.093 2.62.134v3.037l-1.797.001c-1.41 0-1.683.67-1.683 1.653v2.168h3.362l-.438 3.396h-2.924V23.5h5.733c.686 0 1.242-.556 1.242-1.242V2.242C23.5 1.556 22.944 1 22.258 1"
                        fill="currentColor"
                      />
                    </svg>
                    <span className="ml-3 hover:text-indigo-600">Facebook</span>
                  </Link>
                </li>
              </ul>
            </div>

            {/* Contact form */}
            <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12 justify-center">
              {ContactForm()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
