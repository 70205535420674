import { articleCategories, articles } from "../../data/articles";
import Placeholder from "../../components/images/placeholder.png";
import { FadeIn, FadeInStagger } from "../utils/FadeIn";

// const articles = [
//     {
//       title: 'Boost your conversion rate',
//       href: '#',
//       category: { name: 'Article', href: '#' },
//       description:
//         'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.',
//       date: 'Mar 16, 2020',
//       datetime: '2020-03-16',
//       imageUrl:
//         'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
//       readingTime: '6 min',
//       author: {
//         name: 'Roel Aufderehar',
//         href: '#',
//         imageUrl:
//           'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//       },
//     },
//     {
//       title: 'How to use search engine optimization to drive sales',
//       href: '#',
//       category: { name: 'Video', href: '#' },
//       description:
//         'Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis asperiores porro quaerat doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi., tempore temporibus quo laudantium.',
//       date: 'Mar 10, 2020',
//       datetime: '2020-03-10',
//       imageUrl:
//         'https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
//       readingTime: '4 min',
//       author: {
//         name: 'Brenna Goyette',
//         href: '#',
//         imageUrl:
//           'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//       },
//     },
//     {
//       title: 'Improve your customer experience',
//       href: '#',
//       category: { name: 'Case Study', href: '#' },
//       description:
//         'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.',
//       date: 'Feb 12, 2020',
//       datetime: '2020-02-12',
//       imageUrl:
//         'https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
//       readingTime: '11 min',
//       author: {
//         name: 'Daniela Metz',
//         href: '#',
//         imageUrl:
//           'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//       },
//     },
//   ]

export default function HelpSection() {
  return (
    <div className="relative bg-gray-50 px-6 pt-16 pb-20 lg:px-8 lg:pt-24 lg:pb-28">
      <div className="absolute inset-0">
        <div className="h-1/3 bg-white sm:h-2/3" />
      </div>
      <div className="relative mx-auto max-w-7xl">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Hjelp & Artikler
          </h2>
          <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-500 sm:mt-4">
            Våre artikkler som kan hjelpe deg å forbedre ditt inneklima.
          </p>
        </div>
        <FadeInStagger className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
          {articles.map((article) => (
            <FadeIn
              key={article.title}
              className="flex flex-col overflow-hidden rounded-lg shadow-lg hover:scale-125"
            >
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover transition delay-150 duration-300 ease-in-out"
                  src={article?.imageSrc ? article.imageSrc : Placeholder}
                  alt=""
                />
              </div>
              <div className="flex flex-1 flex-col justify-between bg-white p-6">
                <div className="flex-1">
                  <p className="text-sm font-medium text-indigo-600">
                    <a className="hover:underline">
                      {articleCategories[article.category - 1].title}
                    </a>
                  </p>
                  <a href={article.href} className="mt-2 block">
                    <p className="text-xl font-semibold text-gray-900">
                      {article.title}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      {article.description}
                    </p>
                  </a>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <a href={article.author.href}>
                      <span className="sr-only">{article.author.name}</span>
                      <img
                        className="h-10 w-10 rounded-full"
                        src={
                          article.author.imageUrl
                            ? article.author.imageUrl
                            : Placeholder
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      <a href={article.author.href} className="hover:underline">
                        {article.author.name}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </FadeIn>
          ))}
        </FadeInStagger>
      </div>
    </div>
  );
}
