import { Fragment, useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";

import {
  CheckCircleIcon,
  ChevronDownIcon,
  PhoneIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { removeItem, resetCart } from "../../slices/cartSlice";
import {
  TWclassNames,
  imgPlaceholder,
  priceFormatter,
} from "../../components/utils/Utils";
import { totalExVat, totalInclVat } from "../../components/utils/orderUtils";
import { Formik, Form, Field } from "formik";
import toast from "react-hot-toast";
import axios from "./../../api/axios";
import { ResourceLoading } from "../../components/utils/ResourceLoading";
import * as Yup from "yup";
import { useSettings } from "../../contexts/settingsContext";
import { Link } from "react-router-dom";
import { StoreNotActiveNotification } from "../../components/notifications";
import { fetchShippingTypes } from "../../api/shipping_types";

export default function OrderCheckout() {
  const [deliveryMethods, setDeliveryMethods] = useState([]);

  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const { settings, setSettings } = useSettings();

  const [open, setOpen] = useState(false);
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(
    deliveryMethods[0]
  );

  const [isLoading, setIsLoading] = useState(false);

  function handleDeliveryMethodChange(value, formik) {
    setSelectedDeliveryMethod(value);
    formik.setFieldValue("shipping_type_id", value?.id);
  }

  useEffect(() => {
    const getDeliveryMethods = async () => {
      const response = await fetchShippingTypes()
        .then((response) => {
          setDeliveryMethods(response?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getDeliveryMethods();
  }, []);

  // POST Checkout with axios
  const handleSubmit = async (values, formik) => {
    try {
      setIsLoading(true);
      console.log(values);
      const response = await axios.post("/checkout", values);
      console.log(response);
      formik.setStatus({ success: true });
      dispatch(resetCart());
      // Redirect to url from response
      window.location.replace(response.data.redirect_url);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      formik.setStatus({ success: false });
    } finally {
      setIsLoading(false);
      formik.setSubmitting(false);
    }
  };

  // Cart is now a object of cart, take the items out of cart, cart object
  const cartItems = Object.values(cart.cart);

  return (
    <div className="mx-auto max-w-7xl px-4 pt-16 pb-24 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-2xl lg:max-w-none">
        <h1 className="sr-only">Utsjekking</h1>

        <Formik
          initialValues={{
            user_id: "",
            name: "",
            email: "",
            mobile: "",
            delivery_address: {
              name: "",
              address: "",
              address2: "",
              postal: "",
              place: "",
              country: "",
            },
            shipping_type_id: "",
            cart: cartItems,
            submit: null,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Navn er påkrevd"),
            email: Yup.string().required("Navn er påkrevd"),
            mobile: Yup.string().required("Navn er påkrevd"),
          })}
          onSubmit={(values, helpers) => {
            console.log(values);
            handleSubmit(values, helpers);

            // try {
            //   toast.promise(() => false, {
            //     loading: "Laster...",
            //     success: (data) => {
            //       console.log(data);
            //       if (data?.status === 200) {
            //         return "Data ble lagret!";
            //       } else {
            //         throw new Error("Noe gikk galt....");
            //       }
            //     },
            //     error: (error) => {
            //       console.log(error);
            //       return "Noe gikk galt, kan ikke gå videre";
            //     },
            //   });
            //   helpers.setStatus({ success: true });
            // } catch (err) {
            //   toast.error("Something went wrong...");
            //   helpers.setStatus({ success: false });
            //   helpers.setErrors({ submit: err.message });
            //   // helpers.setSubmitting(false);
            // }
          }}
        >
          {(formik) => (
            <Form className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
              <div className="col-span-2">
                {!settings?.checkout_active && <StoreNotActiveNotification />}
              </div>
              {!isLoading ? (
                <div>
                  <div>
                    <h2 className="text-lg font-medium text-gray-900">
                      Kontaktinformasjon
                    </h2>
                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Navn / Bedrift
                        </label>
                        <div className="mt-1">
                          <Field
                            id="name"
                            name="name"
                            type="text"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <label
                          htmlFor="email-address"
                          className="block text-sm font-medium text-gray-700"
                        >
                          E-post
                        </label>
                        <div className="mt-1">
                          <Field
                            type="email"
                            id="email-address"
                            name="email"
                            autoComplete="email"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <label
                          htmlFor="phone"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Mobilnummer
                        </label>
                        <div className="mt-1">
                          <Field
                            type="text"
                            name="mobile"
                            id="phone"
                            autoComplete="tel"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-10 border-t border-gray-200 pt-10">
                    <h2 className="text-lg font-medium text-gray-900">
                      Leveringsadresse
                    </h2>

                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Navn
                        </label>
                        <div className="mt-1">
                          <Field
                            type="text"
                            id="first-name"
                            name="name"
                            autoComplete="given-name"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <label
                          htmlFor="address"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Adresse
                        </label>
                        <div className="mt-1">
                          <Field
                            type="text"
                            name="delivery_address.address"
                            id="address"
                            autoComplete="street-address"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <label
                          htmlFor="apartment"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Adresse felt 2
                        </label>
                        <div className="mt-1">
                          <Field
                            type="text"
                            name="delivery_address.address2"
                            id="apartment"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="city"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Sted
                        </label>
                        <div className="mt-1">
                          <Field
                            type="text"
                            name="delivery_address.place"
                            id="city"
                            autoComplete="address-level2"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="postal-code"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Postnummer
                        </label>
                        <div className="mt-1">
                          <Field
                            type="text"
                            name="delivery_address.postal"
                            id="postal-code"
                            autoComplete="postal-code"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Land
                        </label>
                        <div className="mt-1">
                          <select
                            id="country"
                            name="country"
                            autoComplete="country-name"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          >
                            <option>Norge</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-10 border-t border-gray-200 pt-10">
                    <RadioGroup
                      value={selectedDeliveryMethod}
                      onChange={(data) =>
                        handleDeliveryMethodChange(data, formik)
                      }
                    >
                      <RadioGroup.Label className="text-lg font-medium text-gray-900">
                        Frakt
                      </RadioGroup.Label>

                      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                        {deliveryMethods.map((deliveryMethod) => (
                          <RadioGroup.Option
                            key={deliveryMethod.id}
                            value={deliveryMethod}
                            className={({ checked, active }) =>
                              TWclassNames(
                                checked
                                  ? "border-transparent"
                                  : "border-gray-300",
                                active ? "ring-2 ring-blue-500" : "",
                                "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
                              )
                            }
                          >
                            {({ checked, active }) => (
                              <>
                                <span className="flex flex-1">
                                  <span className="flex flex-col">
                                    <RadioGroup.Label
                                      as="span"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      {deliveryMethod.title}
                                    </RadioGroup.Label>
                                    <RadioGroup.Description
                                      as="span"
                                      className="mt-1 flex items-center text-sm text-gray-500"
                                    >
                                      {deliveryMethod.description}
                                    </RadioGroup.Description>
                                    <RadioGroup.Description
                                      as="span"
                                      className="mt-6 text-sm font-medium text-gray-900"
                                    >
                                      {deliveryMethod?.price_incl_vat > 0 &&
                                        priceFormatter.format(
                                          deliveryMethod?.price_incl_vat
                                        )}
                                    </RadioGroup.Description>
                                  </span>
                                </span>
                                {checked ? (
                                  <CheckCircleIcon
                                    className="h-5 w-5 text-blue-600"
                                    aria-hidden="true"
                                  />
                                ) : null}
                                <span
                                  className={TWclassNames(
                                    active ? "border" : "border-2",
                                    checked
                                      ? "border-blue-500"
                                      : "border-transparent",
                                    "pointer-events-none absolute -inset-px rounded-lg"
                                  )}
                                  aria-hidden="true"
                                />
                              </>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>
                </div>
              ) : (
                <ResourceLoading />
              )}

              {/* Order summary */}
              <div className="mt-10 lg:mt-0">
                <h2 className="text-lg font-medium text-gray-900">
                  Din bestilling
                </h2>

                <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
                  <h3 className="sr-only">Items in your cart</h3>
                  <ul role="list" className="divide-y divide-gray-200">
                    {cart.cart.map((product) => (
                      <li key={product.id} className="flex py-6 px-4 sm:px-6">
                        <div className="flex-shrink-0">
                          <img
                            src={
                              product?.main_picture?.file?.url
                                ? product?.main_picture?.file?.url
                                : imgPlaceholder
                            }
                            alt={product?.main_picture?.alt}
                            className="w-20 rounded-md"
                          />
                        </div>

                        <div className="ml-6 flex flex-1 flex-col">
                          <div className="flex">
                            <div className="min-w-0 flex-1">
                              <h4 className="text-sm">
                                <a
                                  href={product.href}
                                  className="font-medium text-gray-700 hover:text-gray-800"
                                >
                                  {product.title}
                                </a>
                              </h4>
                              <p className="mt-1 text-sm text-gray-500">
                                {product.item_number}
                              </p>
                              <p className="mt-1 text-gray-800">
                                {priceFormatter.format(
                                  product.sales_price_incl_vat
                                )}
                              </p>
                            </div>

                            <div className="ml-4 flow-root flex-shrink-0">
                              <button
                                type="button"
                                className="-m-2.5 flex items-center justify-center bg-white p-2.5 text-gray-400 hover:text-gray-500"
                              >
                                <span className="sr-only">Ta bort</span>
                                <button
                                  type="button"
                                  onClick={() =>
                                    dispatch(removeItem(product.id))
                                  }
                                >
                                  <TrashIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </button>
                              </button>
                            </div>
                          </div>

                          <div className="flex flex-1 items-end justify-between ">
                            <p className="mt-1 text-sm font-medium text-gray-900">
                              {product.price}
                            </p>

                            <div className="ml-4">
                              <label htmlFor="quantity" className="sr-only">
                                Antall
                              </label>
                              <span className="inline-flex items-center">
                                <span className="pl-2 pr-2">
                                  {product.quantity}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <dl className="space-y-6 border-t border-gray-200 py-6 px-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <dt className="text-sm">
                        Totalt
                        <dt className="text-xs text-gray-400">eks. mva</dt>
                      </dt>

                      <dd className="text-sm font-medium text-gray-900">
                        {priceFormatter.format(totalExVat(cart.cart))}
                      </dd>
                    </div>
                    <div className="flex items-center justify-between">
                      <dt className="text-sm">
                        Frakt
                        <dt className="text-xs text-gray-400">eks. mva</dt>
                      </dt>

                      <dd className="text-sm font-medium text-gray-900">
                        {priceFormatter.format(selectedDeliveryMethod?.price)}
                      </dd>
                    </div>
                    <div className="flex items-center justify-between">
                      <dt className="text-sm">Moms</dt>
                      <dd className="text-sm font-medium text-gray-900">
                        {priceFormatter.format(
                          totalInclVat(cart.cart) -
                            totalExVat(cart.cart) +
                            (selectedDeliveryMethod?.price_incl_vat -
                              selectedDeliveryMethod?.price)
                        )}
                      </dd>
                    </div>
                    <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                      <dt className="text-base font-medium">Totalt</dt>
                      <dd className="text-base font-medium text-gray-900">
                        {priceFormatter.format(
                          totalInclVat(cart.cart) +
                            (selectedDeliveryMethod &&
                            selectedDeliveryMethod?.price_incl_vat > 0
                              ? +selectedDeliveryMethod?.price_incl_vat
                              : null)
                        )}
                      </dd>
                    </div>
                  </dl>

                  <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                    {settings?.checkout_active && selectedDeliveryMethod && (
                      <button
                        type="submit"
                        className="w-full rounded-md border border-transparent bg-blue-400 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                      >
                        Til betaling
                      </button>
                    )}

                    {!selectedDeliveryMethod && (
                      <span className="text-red-400">
                        Du må velge en fraktmetode
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
