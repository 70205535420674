import ProductQuickview from "./quickview";
import { useState } from "react";
import Placeholder from "../../components/images/placeholder.png";
import { StringLimit, priceFormatter } from "../../components/utils/Utils";
import { ResourceLoading } from "../../components/utils/ResourceLoading";
import { ResourceError } from "../../components/utils/ResourceError";
import { ProductWarehouseStatus } from "../../components/products/utils";
import { FadeIn, FadeInStagger } from "../../components/utils/FadeIn";

export const ProductsList = (props) => {
  const { products, isLoading, error, errors } = props;

  const [quickviewOpen, setQuickviewOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleQuickview = (product) => {
    setSelectedProduct(product);
    setQuickviewOpen(true);
  };
  if (isLoading) return <ResourceLoading />;
  if (error) return <ResourceError errors={errors} />;
  return (
    <>
      <div className="m-5">
        <FadeInStagger className="grid grid-cols-1 gap-y-4 sm:grid-cols-3 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-auto lg:gap-x-8">
          {products?.map((product) => (
            <FadeIn
              onClick={() => handleQuickview(product)}
              key={product?.id}
              className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
            >
              <div className="absolute top-3 left-5">
                <ProductWarehouseStatus product={product} />
              </div>
              <div className="aspect-w-3 aspect-h-4 bg-gray-200 group-hover:opacity-75 sm:aspect-none sm:h-60">
                <img
                  src={
                    product?.main_picture?.file?.url
                      ? product?.main_picture?.file?.url
                      : Placeholder
                  }
                  alt={product?.main_picture?.alt}
                  className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                />
              </div>
              <div className="flex flex-1 flex-col space-y-2 p-4">
                <h3 className="text-sm font-medium text-gray-900">
                  <a href={product?.href}>
                    <span aria-hidden="true" className="absolute inset-0" />
                    <StringLimit limit={35} text={product?.title} />
                  </a>
                </h3>
                <p>{product?.item_number}</p>
                <p className="text-sm text-gray-500">
                  <StringLimit limit={100} text={product?.short_description} />
                </p>
                <div className="flex flex-1 flex-col justify-end">
                  <p className="text-sm italic text-gray-500">
                    {product.options}
                  </p>

                  {product?.warehouse_status_type_id !== 4 &&
                    product.sales_price > 0 && (
                      <div className="">
                        <p className="text-base font-medium text-gray-900">
                          {priceFormatter.format(product?.sales_price_incl_vat)}
                        </p>
                        <span className="italic text-xs py-1">
                          {" inkl. mva"}
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </FadeIn>
          ))}
        </FadeInStagger>
      </div>
      <ProductQuickview
        selectedProduct={selectedProduct}
        onClose={() => setQuickviewOpen(false)}
        open={quickviewOpen}
      />
    </>
  );
};
