import { FadeIn, FadeInStagger } from "./FadeIn";

export const PageHeading = (props) => {
  return (
    <FadeInStagger className="bg-blue-500 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <FadeIn>
            <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              {props.title}
            </h2>
          </FadeIn>
          <FadeIn>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              {props.description}
            </p>
          </FadeIn>
        </div>
      </div>
    </FadeInStagger>
  );
};
