import { PhoneIcon, QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

export const StoreNotActiveNotification = () => {
  return (
    <div className="rounded-xl bg-yellow-50 p-4 my-5">
      <div className="flex">
        <div className="flex-shrink-0">
          <QuestionMarkCircleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>

        <div className="ml-3 flex-1 md:flex md:justify-between">
          <div className="flex flex-col">
            <h3>Nettbutikken er nede for øyeblikket</h3>
            <p className="text-sm text-yellow-700">
              Da kontakt med oss, så hjelper vi deg men din bestilling.
            </p>
          </div>

          <p className="mt-3 text-sm md:mt-0 md:ml-6">
            <Link
              to="/kontakt"
              className="flex whitespace-nowrap font-medium text-white hover:text-blue-600 px-3 py-3 rounded-xl bg-blue-400 "
            >
              <PhoneIcon className="h-5 w-5 mr-2" />
              Kontakt oss
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
