import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { ArrowLeftIcon, ChartBarIcon, KeyIcon, UserPlusIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/outline'
import { ShoppingCartIcon } from '@heroicons/react/24/solid'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'


export default function CustomerPopover() {
    const customerPages = [
      {
        name: 'Oversikt',
        description: 'Din side',
        href: '/min-side/',
        icon: ChartBarIcon,
        isShowing: isAuthenticated() ? true : false
    
      },
      {
        name: 'Mine bestillinger',
        description: 'Oversikt over dine bestillinger',
        href: '/min-side/ordrer',
        icon: ShoppingCartIcon,
        isShowing: isAuthenticated() ? true : false
      },
      {
        name: 'Rapporter',
        description: 'Oversikt over alle oppdrag vi har utført på dine anlegg',
        href: '/min-side/rapporter',
        icon: WrenchScrewdriverIcon,
        isShowing: isAuthenticated() ? true : false
      },
      {
        name: 'Logg inn',
        description: 'Keep track of your growth',
        href: '/bruker/logg-inn',
        icon: KeyIcon,
        isShowing: isAuthenticated() ? false : true
    
      },
      {
        name: 'Registrer deg',
        description: 'Keep track of your growth',
        href: '/bruker/registrering',
        icon: UserPlusIcon,
        isShowing: isAuthenticated() ? false : true
      },
    ]

    function isAuthenticated(){
        return true
    }



  return (
    <div className="z-10">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'}
                group inline-flex items-center rounded-md bg-blue-400 px-3 py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span>Min side</span>
              <ChevronDownIcon
                className={`${open ? '' : 'text-opacity-70'}
                  ml-2 h-5 w-5 text-white transition duration-150 ease-in-out group-hover:text-opacity-80`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-2/4 shadow border-2 border-blue-300 z-10 mt-3 w-[300px] max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-1">
                    {customerPages.filter(page => page.isShowing === true).map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12 bg-blue-400 rounded p-2">
                          <item.icon aria-hidden="true" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium text-gray-900">
                            {item.name}
                          </p>
                          <p className="text-sm text-gray-500">
                            {item.description}
                          </p>
                        </div>
                      </Link>
                    ))}
                  </div>
                  <div className="bg-gray-50 p-4">
                    <a
                      href="##"
                      className="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                    >
                    { isAuthenticated &&
                      <span className="flex items-center">
                        <ArrowLeftIcon className="h-5 w-5"/>
                        <Link className="" to="/bruker/logg-ut">
                        <span className="pl-2 text-sm font-medium text-gray-900">
                              Logg ut
                        </span>
                        </Link>
                      </span>
                    }
                    </a>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}


