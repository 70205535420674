import { configureStore, ThunkAction, Action, combineReducers, PreloadedState, StateFromReducersMapObject } from "@reduxjs/toolkit";

// import sessionReducer from "./slices/session";
import { reducer as productsReducer } from './slices/products';
import { reducer as cartReducer } from './slices/cartSlice';



const reducer = {
    // session: sessionReducer,
    products: productsReducer,
    cart: cartReducer
};


const loadStateFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveStateToLocalStorage = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);  
    localStorage.setItem('state', serializedState);
  } catch (err) {
    console.error(err);
  }
};


export type RootState = StateFromReducersMapObject<typeof reducer>

export function initStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer,
    preloadedState,
  });
}

export const store = initStore(loadStateFromLocalStorage());

store.subscribe(() => saveStateToLocalStorage(store.getState()));

type Store = ReturnType<typeof initStore>
type RootState__ = ReturnType<Store['getState']> //alternate way
export type AppDispatch = Store['dispatch']
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;