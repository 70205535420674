import { Link } from "react-router-dom";
import HelpSection from "../components/home/helpSection";
import ServicesSection from "../components/home/servicesSection";
import WebshopIncentives from "../components/home/webshopIncentives";
import { FadeIn } from "../components/utils/FadeIn";
import { PMCard } from "../components/utils/PMCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NyhetSaveConnect from "../components/images/slider/nyhet-save-connect.png";
import Innregulering from "../components/images/slider/inn-regulering.png";
import { TWclassNames } from "../components/utils/Utils";

const SlideOneContent = ({
  subtitle,
  title,
  subheading,
  buttons,
  imagePath,
  bgColor,
  textColor,
}) => {
  const bgStyle = imagePath
    ? {
        backgroundImage: `url(${imagePath})`,
      }
    : { backgroundColor: bgColor };

  return (
    <div
      className="relative flex flex-col justify-center h-full px-4 py-16 sm:px-6 sm:py-24 lg:p-32 xl:p-48"
      style={bgStyle}
    >
      <div className="max-w-3xl mx-auto text-center">
        <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
          <span
            className={TWclassNames(
              textColor ? textColor : "text-white",

              "block italic font-light"
            )}
          >
            {subtitle}
          </span>
          <span
            className={TWclassNames(
              textColor ? textColor : "text-white",
              "block"
            )}
          >
            {title}
          </span>
        </h1>
        <p
          className={TWclassNames(
            textColor ? textColor : "text-white",
            "mt-6 text-xl  max-w-3xl"
          )}
        >
          {subheading}
        </p>
        {buttons && (
          <div className="mt-6">
            {buttons?.map((button, index) => (
              <Link
                key={index}
                to={button.path}
                className="inline-flex items-center justify-center px-5 py-3 border-2 text-blue-600 hover:shadow-md hover:text-blue-700 font-medium rounded-md   border-blue-600 hover:border-blue-700"
              >
                {button.label}
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const slides = [
  {
    id: 1,
    content: (
      <SlideOneContent
        textColor="text-indigo-700"
        title="med full service"
        subtitle="Sunnere inneklima"
        subheading="Full service bør utføres hvert 3-4 år. Vi utfører service på alle typer anlegg."
        buttons={[{ label: "Les mer", path: "/tjenester/full-service" }]}
        imagePath={Innregulering}
      />
    ),
  },
  {
    id: 2,
    content: (
      <SlideOneContent
        textColor="text-blue-400"
        title="med serviceavtale"
        subtitle="Vi følger opp ditt anlegg"
        subheading="Vi tilbyr alt fra filteravtale til full serviceavtale."
        buttons={[{ label: "Les mer", path: "/tjenester/serviceavtale" }]}
      />
    ),
  },
  {
    id: 3,
    content: (
      <SlideOneContent
        textColor="text-blue-400"
        title="SAVE CONNECT 2.0"
        subtitle="Nyhet til ditt anlegg"
        // subheading="Vi tilbyr alt fra filteravtale til full serviceavtale."
        buttons={[{ label: "Se produkt", path: "/produkter/save-connect-2-0" }]}
        imagePath={NyhetSaveConnect}
      />
    ),
  },

  // add more slides as needed
];

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  autoplay: false,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  // slidesToScroll: 0,
};

export const Home = () => {
  return (
    <>
      <div className="relative top-[-50px]">
        <FadeIn id="slide">
          <Slider {...sliderSettings}>
            {slides.map((slide) => (
              <div key={slide.id}>{slide.content}</div>
            ))}
          </Slider>
        </FadeIn>
        <div className="relative grid gap-5 grid-cols-1 sm:grid-cols-2 mx-auto max-w-7xl mt-5 sm:px-6 lg:px-8 top-[-100px]">
          <PMCard
            title="Service & Support"
            description="Trenger du kun teknisk support eller full service? Vi er her for deg"
            imageUrl=""
            buttonLabel="Kontakt oss"
            path="/kontakt"
          />
          <PMCard
            title="Serviceavtale"
            description="Vi tilbyr alt fra filteravtale til full serviceavtale"
            imageUrl=""
            buttonLabel="Les mer"
            path="/tjenester/serviceavtale"
          />
        </div>
      </div>
      {/* Services Section  */}
      <ServicesSection />
      {/* Services Section  END */}

      <WebshopIncentives />

      {/* Help Section */}
      <HelpSection />
      {/* Help Section END*/}
    </>
  );
};
