import { Fragment, useState } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { FaceFrownIcon, GlobeAmericasIcon } from '@heroicons/react/24/outline'
import { Combobox, Dialog, Transition } from '@headlessui/react'

const items = [
  { id: 1, name: "Workflow Inc.", category: "Clients", url: "#" },
  // More items...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SearchQuery(props) {

  const { onChange, label, placeholder } = props;

  const [query, setQuery] = useState(props.query);

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleKeyup = (event) => {
    if (event.keyCode === 13 && onChange) {
      onChange(query);
    }
  };

  const filteredItems =
    query === ""
      ? []
      : items.filter((item) => {
          return item.name.toLowerCase().includes(query.toLowerCase());
        });

  const groups = filteredItems.reduce((groups, item) => {
    return {
      ...groups,
      [item.category]: [...(groups[item.category] || []), item],
    };
  }, {});

  return (
    <div className="relative bg-white rounded-md border-2 border-blue-400 px-3 py-2 shadow-sm focus-within:border-blue-400 focus-within:ring-1 focus-within:ring-indigo-600">
      <label htmlFor="name" className="absolute -top-2 left-2 -mt-px inline-block bg-white rounded shadow  px-2 py-1 text-xs font-medium text-gray-900">
        {label}
      </label>
      <div className="mt-1">
        <input
          onKeyUp={handleKeyup}
          type="text"
          name="product-search"
          id="product-search"
          onChange={handleChange}
          className="w-full rounded-md border-white px-5 py-3 placeholder-gray-500 focus:outline-none"
          value={query}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}

SearchQuery.defaultProps = {
  label: 'Søk produkter',
  placeholder: "Søk etter produkter...."
}