import { PageHeading } from "../../components/utils/pageHeading";
import ServiceSection from "../../components/utils/serviceSection";

export const ServiceBolig = () => {
  return (
    <>
      <div>
        <PageHeading title={"Service for boliganlegg"} />

        <ServiceSection imagePlacement="right" />
      </div>
    </>
  );
};
