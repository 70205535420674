import { Field, validateYupSchema } from "formik";
import PropTypes from "prop-types";
import {
  ClipboardDocumentIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ClipboardDocumentCheckIcon } from "@heroicons/react/20/solid";
const PMTextArea = (props) => {
  return <textarea {...props} rows={5} />;
};

export const PMField = (props) => {
  const {
    type,
    name,
    label,
    multiline,
    touched,
    errors,
    onBlur,
    onChange,
    value,
    values,
    readOnly,
    onClick,
    setFieldValue,
    description,
    disabled,
    formik,
    placeholder,
    ...other
  } = props;

  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = (value) => {
    if (!value) {
      setIsCopied(false);
      toast.error("Cannot copy an empty field");
    } else {
      navigator.clipboard.writeText(value);
      setIsCopied(true);
    }
  };

  // useEffect(() => {
  //     console.log(values)
  // }, [values])

  if (type === "checkbox") {
    return (
      <div className="pt-6 sm:pt-5">
        <div role="group" aria-labelledby="label-email">
          <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4">
            <div className="mt-4 sm:col-span-2 sm:mt-0">
              <div className="max-w-lg space-y-4">
                <div className="relative flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id={name}
                      name={name}
                      type="checkbox"
                      value={formik?.values[name] || value}
                      onChange={
                        onChange
                          ? onChange
                          : () => setFieldValue(name, !values[name])
                      }
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="comments"
                      className="font-medium text-gray-700"
                    >
                      {label}
                    </label>
                    <p className="text-gray-500">{description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700"
        >
          <label> {label} </label>
        </label>
        <div className="relative mt-1 border-gray-300 focus-within:border-indigo-600">
          {formik ? (
            <Field
              className="block w-full border-0 border-b border-transparent bg-blue-50 focus:border-blue-500 focus:ring-0 sm:text-sm"
              onClick={onClick}
              type={type}
              value={values ? values[name] : value}
              name={name}
              onBlur={onBlur}
              as={multiline ? PMTextArea : "input"}
              disabled={readOnly || disabled}
              placeholder={placeholder}
              // {...other}
            />
          ) : (
            <input
              className="block w-full border-0 border-b border-transparent bg-blue-50 focus:border-blue-500 focus:ring-0 sm:text-sm"
              onClick={onClick}
              onChange={onChange}
              type={type}
              name={name}
              onBlur={onBlur}
              disabled={readOnly || disabled}
              placeholder={placeholder}
              value={value}
            />
          )}
          {readOnly ? (
            <div
              className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3 "
              onClick={() => {
                copyToClipboard(values ? values[name] : value);
              }}
            >
              {isCopied ? (
                <ClipboardDocumentCheckIcon
                  className="text-green-500 h-5 w-5"
                  aria-hidden="true"
                />
              ) : (
                <ClipboardDocumentIcon
                  className="text-gray-400 h-5 w-5 hover:text-blue-400"
                  aria-hidden="true"
                />
              )}
            </div>
          ) : null}
        </div>

        {formik && touched[name] && errors[name] && (
          <div className="flex mt-1 text-red-700 text-xs">
            <ExclamationCircleIcon className="w-4 text-xs" />
            <span className="ml-2">{errors[name]}</span>
          </div>
        )}
      </div>
    );
  }
};

PMField.defaultProps = {
  type: "text",
  formik: true,
  variant: "default",
};

PMField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
};
