import { Link } from "react-router-dom";
import { categoryPath, imgPlaceholder } from "../../components/utils/Utils";
import { FadeIn, FadeInStagger } from "../../components/utils/FadeIn";

export const Categories = (props) => {
  const { categories } = props;

  return (
    <FadeInStagger className="grid grid-cols-1 gap-y-4 sm:grid-cols-5 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-auto lg:gap-x-8">
      {categories?.map((category) => (
        <FadeIn key={category?.slug}>
          <Link to={categoryPath(category)}>
            <div
              key={category?.id}
              className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
            >
              <div className="aspect-w-3 aspect-h-4 bg-gray-200 group-hover:opacity-75 sm:aspect-none sm:h-60">
                <img
                  src={
                    category?.main_picture?.file?.url
                      ? category?.main_picture?.file?.url
                      : imgPlaceholder
                  }
                  alt={category?.main_picture?.alt}
                  className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                />
              </div>
              <div className="flex flex-1 flex-col space-y-2 p-4">
                <h3 className="text-sm font-medium text-gray-900">
                  <a href={category?.href}>
                    <span aria-hidden="true" className="absolute inset-0" />
                    {category?.title}
                  </a>
                </h3>
                <p>{category?.description}</p>
                <p className="text-sm text-gray-500">
                  {category.short_description}
                </p>
              </div>
            </div>
          </Link>
        </FadeIn>
      ))}
    </FadeInStagger>
  );
};
