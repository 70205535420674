export const employees = [
  {
    name: "Christoffer Skaar",
    role: "Daglig leder",
    imageUrl: null,
    mobile: "+47 69142600",
    email: "cs@airon.no",
  },
  {
    name: "Ivar Ulsness",
    role: "Servicetekniker",
    imageUrl: null,
    mobile: "+47 69142600",
    email: "cs@airon.no",
  },
  {
    name: "Charlotte Skaar",
    role: "Administrasjon",
    imageUrl: null,
    mobile: "+47 69142600",
    email: "charlotte@airon.no",
  },

  // More people...
];
