import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ShoppingBagIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { priceFormatter } from "./Utils";
import { useDispatch, useSelector } from "react-redux";
import {
  decrementQuantity,
  incrementQuantity,
  removeItem,
} from "../../slices/cartSlice";
import { totalInclTax, totalInclVat } from "./orderUtils";
import Placeholder from "../../components/images/placeholder.png";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/20/solid";

export default function ShoppingCart(props) {
  const [open, setOpen] = useState(false);
  const cart = useSelector((state) => state.cart);
  return (
    <>
      <div
        className="cursor-pointer group m-2 inline-flex items-center rounded-md bg-indigo-400 px-4 py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        onClick={() => setOpen(true)}
      >
        <ShoppingBagIcon className="h-5 w-5 " />
        <span className="ml-2 font-thin italic">
          {priceFormatter.format(totalInclVat(cart.cart))}
        </span>
      </div>
      {
        <ShoppingCartSlidover
          cart={cart}
          open={open}
          onOpen={() => setOpen(!open)}
        />
      }
    </>
  );
}


function ShoppingCartSlidover(props) {
  const { open, onOpen, cart } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCheckout = () => {
    onOpen();
    navigate("/ordre/betaling");
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Handlekurv
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={onOpen}
                          >
                            <span className="sr-only">Steng handlekurv</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>

                      <div className="mt-8">
                        <div className="flow-root">
                          <ul
                            role="list"
                            className="-my-6 divide-y divide-gray-200"
                          >
                            {cart.cart.map((product) => (
                              <li key={product.id} className="flex py-6">
                                <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                  <img
                                    src={
                                      product?.main_picture?.file?.url
                                        ? product?.main_picture?.file?.url
                                        : Placeholder
                                    }
                                    alt={product?.main_picture?.alt}
                                    className="h-full w-full object-cover object-center"
                                  />
                                </div>

                                <div className="ml-4 flex flex-1 flex-col">
                                  <div>
                                    <div className="flex justify-between text-base font-medium text-gray-900">
                                      <h3>
                                        <a href={product.href}>
                                          {product.title}
                                        </a>
                                      </h3>

                                      <p className="ml-4">
                                        {priceFormatter.format(
                                          product.sales_price_incl_vat
                                        )}
                                      </p>
                                    </div>
                                    <p className="mt-1 text-sm text-gray-500">
                                      {product.color}
                                    </p>
                                  </div>
                                  <div>
                                    <p>{product?.item_number}</p>
                                  </div>
                                  <div className="flex flex-1 items-end justify-between text-sm">
                                    <p className="text-gray-500">
                                      Antall: {product.quantity}
                                    </p>

                                    <div className="flex">
                                      <button
                                        type="button"
                                        className=""
                                        onClick={() => {
                                          dispatch(
                                            incrementQuantity(product.id)
                                          );
                                        }}
                                      >
                                        <PlusCircleIcon
                                          className="h-5 w-5 text-gray-400 hover:text-gray-500 mr-1"
                                          aria-hidden="true"
                                        />
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          dispatch(
                                            decrementQuantity(product.id)
                                          );
                                        }}
                                      >
                                        <MinusCircleIcon
                                          className="h-5 w-5 text-gray-400 hover:text-gray-500 mr-5"
                                          aria-hidden="true"
                                        />
                                      </button>
                                      <button
                                        onClick={() => {
                                          dispatch(removeItem(product.id));
                                        }}
                                        type="button"
                                        className="font-medium text-indigo-600 hover:text-indigo-500"
                                      >
                                        Slett
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                      <div className="flex justify-between text-base font-medium text-gray-900">
                        <p>
                          Totalt{" "}
                          <span className="italic font-thin text-gray-500">
                            inkl. mva.
                          </span>
                        </p>
                        <p>{priceFormatter.format(totalInclVat(cart.cart))}</p>
                      </div>
                      <p className="mt-0.5 text-sm text-gray-500">
                        Frakt tilkommer under utsjekking/ betaling.
                      </p>
                      <div className="mt-6">
                        <button
                          onClick={() => handleCheckout()}
                          type="button"
                          className="flex items-center justify-center rounded-md border border-transparent bg-blue-400 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                        >
                          Til betaling
                        </button>
                      </div>
                      <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                        <p>
                          eller
                          <button
                            type="button"
                            className="font-medium text-blue-400 hover:text-indigo-500 ml-2"
                            onClick={onOpen}
                          >
                            Fortsett å handle
                            <span aria-hidden="true"> &rarr;</span>
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
