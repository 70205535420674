import { CheckCircleIcon } from "@heroicons/react/20/solid";
import Placeholder from "../images/placeholder.png";

export const imgPlaceholder = Placeholder;

export const priceFormatter = new Intl.NumberFormat("nb-NO", {
  style: "currency",
  currency: "NOK",
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const productPath = (product) => {
  if (product && product?.slug) {
    return `/produkter/${product?.slug}`;
  } else {
    return `/produkter/${product?.id}`;
  }
};

export const categoryPath = (category) => {
  if (category && category?.slug) {
    return `/kategorier/${category?.slug}`;
  } else {
    return `/kategorier/${category?.id}`;
  }
};

export function TWclassNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function StringLimit({ text, limit }) {
  if (text) {
    if (text?.length <= limit) {
      return <span>{text}</span>;
    } else {
      return <span>{text?.slice(0, limit)}...</span>;
    }
  } else {
    return null;
  }
}

export const AddToCartToast = ({ visible, product }) => (
  <div
    className={TWclassNames(
      "z-20 flex w-[300px] flex-col items-center space-y-4"
    )}
  >
    <div className="flex py-4 px-4 pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
      <div className="flex-shrink-0">
        <CheckCircleIcon
          className="h-6 w-6 text-green-400"
          aria-hidden="true"
        />
      </div>
      <div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium text-gray-900">
          Lagt til i handlekurven!
        </p>
        <p className="mt-1 text-sm text-gray-500">
          Produktet {product?.title} ble lagt til i handlekurven
        </p>
      </div>
    </div>
  </div>
);
