import { AtSymbolIcon } from "@heroicons/react/24/outline";
import { PhoneIcon } from "@heroicons/react/24/solid";
import placeholder from "../../components/images/placeholder.png";
import { employees } from "../../data/employees";

export const AboutEmployees = () => {
  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 text-center lg:px-8">
        <div className="mx-auto max-w-2xl">
          <h2 className="text-3xl font-bold tracking-tight text-blue-400 sm:text-4xl">
            Våre ansatte
          </h2>
          {/* <p className="mt-4 text-lg leading-8 text-gray-400">
            We’re a dynamic group of individuals who are passionate about what we do.
          </p> */}
        </div>
        <ul
          role="list"
          className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-6 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-8"
        >
          {employees.map((person) => (
            <li
              key={person.name}
              className="rounded-2xl bg-gray-100 radius-xl py-10 px-8 shadow"
            >
              <img
                className="object-cover object-center mx-auto h-48 w-48 rounded-full md:h-56 md:w-56"
                src={person.imageUrl ? person.imageUrl : placeholder}
                alt=""
              />
              <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-800">
                {person.name}
              </h3>
              <p className="text-sm leading-6 text-gray-400">{person.role}</p>
              <ul role="list" className="mt-6 flex justify-center gap-x-6">
                <li>
                  <a
                    href={"mailto:" + person.email}
                    className="text-gray-400 hover:text-gray-300"
                  >
                    <span className="sr-only">E-post</span>
                    <AtSymbolIcon className="h-5 w-5" />
                  </a>
                </li>
                <li>
                  <a
                    href={"tel:" + person.mobile}
                    className="text-gray-400 hover:text-gray-300"
                  >
                    <span className="sr-only">Mobil</span>
                    <PhoneIcon className="h-5 w-5" />
                  </a>
                </li>
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
