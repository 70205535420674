import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchFaqPost } from "../../../api/faq";
import { ResourceLoading } from "../../../components/utils/ResourceLoading";
import { PMNotice } from "../../../components/PMNotice";

export const FaqPostPage = () => {
  // Get slug from params
  const { slug } = useParams();

  const [itemState, setItemState] = useState({
    isLoading: false,
    success: false,
    errors: null,
    faqPost: {},
  });

  const { faqPost } = itemState;

  const fetchfaqPost = async () => {
    setItemState({ ...itemState, isLoading: true });

    const payload = {
      slug: slug,
    };

    const response = await fetchFaqPost(payload);
    if (response.status !== 200) {
      setItemState({ ...itemState, errors: response, isLoading: false });
    } else {
      setItemState({
        ...itemState,
        faqPost: response?.data?.data,
      });
    }
  };

  useEffect(() => {
    fetchfaqPost();
  }, []);

  if (itemState.isLoading) {
    return <ResourceLoading />;
  }

  return (
    <div>
      <section>
        <div className="overflow-hidden bg-white">
          <div className="relative mx-auto max-w-7xl py-16 px-6 lg:px-8">
            <div className="absolute top-0 bottom-0 left-3/4 hidden w-screen lg:block" />
            {faqPost?.id === undefined ? (
              <PMNotice
                title="Er var den ingen innlegg..."
                description="Søk på noe annet eller prøv igjen senere."
                severity="info"
              />
            ) : (
              <>
                <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
                  <div>
                    <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl border-b-2 pb-3 border-blue-400">
                      {faqPost?.title}
                    </h3>
                  </div>
                </div>

                <div className="mt-4 flex items-center gap-x-4 text-xs">
                  <span className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">
                    {faqPost?.category?.title}
                  </span>
                  <time
                    dateTime={faqPost?.updated_at}
                    className="text-gray-500"
                  >
                    {faqPost?.updated_at}
                  </time>
                </div>

                <div className="mt-20">
                  <div
                    className="mt-6"
                    dangerouslySetInnerHTML={{ __html: faqPost?.content }}
                  ></div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};
